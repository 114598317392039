import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { moneyWithCommas } from "../../helpers/RegexHelper";

/**
 * View to display html.
 * 
 * @typedef Properties
 * @type {object}
 * 
 * @property {string} cheapestTeamPackageCost the html string to display
 * 
 * @property {object} style style for the component
 * 
 *
 * @param {Properties} props cheapestTeamPackageCost, style
 */


const RegistrationLineItem = (props) => {

  const {cheapestTeamPackageCost, registrationAction, packagesAvailable, style} = props

  const registrationInfoSection = {
    sectionItems: [
      {
        type: "body no-wrap full-width",
        value: "Registration is Open",
      },
      {
        type: "secondary no-wrap full-width",
        value: `${packagesAvailable ?? 0} Package${packagesAvailable !== 1 ? "s": ""} Available `,
      },
    ]
  }

  const regStartingAtSection = {
    
    sectionItems: [
      {
        type: "no-wrap full-width",
        value: <GSButton style={{flexGrow: 1}} type="grey pill secondary" title={`Teams starting at ${moneyWithCommas(cheapestTeamPackageCost)}`}/>,
      },
    ]
  }

  const registrationActionSection = {
    type:"mobile-stretch",
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSButton style={{flexGrow: 1}} {...registrationAction}/>,
      },
    ]
  }

  const registrationInfoColumn ={
    fit: "stretch",
    sections: [
      registrationInfoSection,
    ],
  }

  if(cheapestTeamPackageCost){
    registrationInfoColumn.sections.push(regStartingAtSection)
  }

  const registrationActionColumn ={
    type:"mobile-stretch",
    sections: [
      registrationActionSection,
    ],
  }

  const getGroup = () => {
    let group = [registrationInfoColumn, registrationActionColumn]
    return group
  }

  return <registration-line-item style={style}>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </registration-line-item>
}


export default RegistrationLineItem