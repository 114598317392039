import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { moneyWithCommas, uuidv4 } from "../helpers/RegexHelper";
import {
  faArrowRight,
  faExclamationCircle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { defaultTheme } from "golfstatus_react_components";
import { summarize } from "../components/events/event/cart/cart-subtotal-item";

/**
 * @property {string} id : uuidv4(),
 * @property {Array} registrationOrderItems : [],
 * @property {Array} donationOrderItems : [],
 * @property {object} tournament : {},
 * @property {Boolean} registrantCoversFees : true,
 * @property {array} discountCodes : [],
 * @property {Boolean} payAtCourse : false,
 * @property {Boolean} internationalCardUsed : false,
 * @property {array} customFieldResponses : [],
 * @property {string} accessEmail : null,
 * @property {string} accessIdentifier : null,
 * @property {string} accessValidated : null,
 * @property {string} status : "",
 * @property {Boolean} customFormValid : true,
 * @property {Boolean} roundUpToggle : false,
 * @property {number} totalCost : 0,
 * @property {string} discountAmount : "",
 * @property {string} discountMessage : null,
 * @property {number} totalPlatformFee : 0,
 * @property  {string} totalCostWithFees : "",
 * @property {number} changeFeeAmount : 0,
 * @property {string}paymentType : "",
 */
export const RegistrationOrder = {
  id: uuidv4(),
  registrationOrderItems: [],
  donationOrderItems: [],
  tournament: {},
  registrantCoversFees: true,
  discountCodes: [],
  payAtCourse: false,
  internationalCardUsed: false,
  customFieldResponses: [],
  accessEmail: null,
  accessIdentifier: null,
  accessValidated: null,
  status: "",
  customFormValid: true,
  roundUpToggle: false,
  totalCost: 0,
  discountAmount: "",
  discountMessage: null,
  totalPlatformFee: 0,
  totalCostWithFees: "",
  changeFeeAmount: 0,
  paymentType: "",
};

/**
 * @property {string} id = uuidv4();
 * @property {number} quantity = 0;
 * @property {object} tournamentPackage = {};
 * @property {array} inProgressTournamentTeams = [];
 * @property {array} inProgressSponsors = [];
 * @property {array} inProgressPlayers = [];
 * @property {number} index = 0;
 * @property {number} packageCost = 0;
 * @property {number}  totalCost = 0;
 */

export const RegistrationOrderItem = {
  id: "",
  quantity: 0,
  tournamentPackage: {},
  //create and pay
  inProgressTournamentTeams: [],
  inProgressSponsors: [],
  inProgressPlayers: [],
  index: 0,
  packageCost: 0,
  totalCost: 0,
};

const newRegistrationOrderItem = (tournamentPackage) => {
  const item = { ...RegistrationOrderItem };
  item.id = uuidv4();
  item.tournamentPackage = tournamentPackage;
  item.quantity = 1;
  return item;
};

export const newDonationOrderItem = (amount) => {
  const item = { id: uuidv4(), amount };
  return item;
};

/**
 * Player setup for create-and-pay
 *
 * @property {Array} formErrors
 * @property {Boolean} customFormValid
 * @property {Boolean} playerFormValid
 * @property {string} tournamentPackageId
 * @property {Array} customFieldResponses
 * @property {Array} roundHoleAssignments
 * @property {number} index
 * @property {Boolean} useRegistrationDetails
 * @property {number} orderItemIndex
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 * @property {string} email
 * @property {Boolean} addNote
 * @property {Boolean} expandedForm
 *
 */
export const InProgressPlayer = {
  id: "",
  formErrors: [],
  customFormValid: true,
  playerFormValid: true,
  tournamentPackageId: "",
  customFieldResponses: [],
  roundHoleAssignments: [],
  index: 0,
  useRegistrationDetails: false,
  orderItemIndex: 0,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  addNote: false,
  expandedForm: true,
};

export const newInProgressPlayer = (playerResponses, useRegistrationDetails) => {
  const player = { ...InProgressPlayer, useRegistrationDetails };
  player.customFieldResponses = playerResponses?.map?.((pr) =>
    newCustomFieldResponse(pr)
  );
  player.id = uuidv4();
  return player;
};

export const InProgressTeam = {
  id: "",
  contactName: "",
  customFieldResponses: [],
  customFormValid: false,
  customNvtFormValid: false,
  customNvtResponse: {},
  email: "",
  formErrors: "",
  inProgressPlayers: [],
  index: 0,
  orderItemIndex: 0,
  phone: "",
  roundHoleAssignments: [],
  teamFormValid: false,
  tournamentPackageId: "",
};

export const newInProgressTeam = (
  playerSize,
  teamResponses,
  playerResponses
) => {
  const team = { ...InProgressTeam };
  const players = Array?.from?.({ length: playerSize }, (x, i) => {
    let player = newInProgressPlayer(playerResponses, i === 0);
    return player;
  });
  team.inProgressPlayers = players;
  team.id = uuidv4();
  team.customFieldResponses = teamResponses?.map?.((pr) =>
    newCustomFieldResponse(pr)
  );
  return team;
};

const getPlayerData = (player, customer, index = 0) => {
  if(player?.useRegistrationDetails){
    return {...player, firstName: customer?.firstName, lastName: customer?.lastName, email: customer?.email, phone: customer?.phone}
  }
  else if((player?.firstName ?? "" ) === "" && (player.lastName ?? "") === ""){
    return {...player, firstName: customer?.firstName, lastName: `${customer?.lastName} ${index + 1}`}
  }
  return player
}

const getSponsorData = (sponsor, customer) => {
  const s = {...sponsor}
  s.contactName = s.contactName === "" ? `${customer.firstName} ${customer.lastName}` : s.contactName
  s.email = s.email === "" ? customer.email : s.email
  s.phone = s.phone === "" ? customer.phone : s.phone
  return s
}

export const updateTeamContact = (registrationOrder, customer) => {
  if (registrationOrder?.registrationOrderItems?.length !== 0) {
    const oldItems = [...registrationOrder?.registrationOrderItems];
    const items = oldItems.map?.((item) => {
      return {
        ...item,
        inProgressTournamentTeams: item.inProgressTournamentTeams?.map?.(
          (t) => {
            return {
              ...t,
              contactName: `${customer?.firstName} ${customer?.lastName}`,
              email: customer?.email,
              phone: customer?.phone,
              inProgressPlayers: t.inProgressPlayers?.map((p, index) => getPlayerData(p, customer, index))
            };
          }
        ),
      };
    });
    registrationOrder.registrationOrderItems = items;
  }
};

export const updatePlayerDetails = (registrationOrder, customer) => {
  if (registrationOrder?.registrationOrderItems?.length !== 0) {
    const oldItems = [...registrationOrder?.registrationOrderItems];
    const items = oldItems.map?.((item) => {
      return {
        ...item,
        inProgressPlayers: item.inProgressPlayers?.map(p => getPlayerData(p, customer))
      };
    });
    registrationOrder.registrationOrderItems = items;
  }
};

export const updateSponsorDetails = (registrationOrder, customer) => {
  if (registrationOrder?.registrationOrderItems?.length !== 0) {
    const oldItems = [...registrationOrder?.registrationOrderItems];
    const items = oldItems.map?.((item) => {
      return {
        ...item,
        inProgressSponsors: item.inProgressSponsors?.map(p => getSponsorData(p, customer))
      };
    });
    registrationOrder.registrationOrderItems = items;
  }
};

export const InProgressSponsor = {
  id: "",
  contactName: "",
  customFieldResponses: [],
  customFormValid: false,
  email: "",
  fileSize: 0,
  formErrors: [],
  index: 0,
  logo: "",
  logoImageDimensions: {},
  logoMobile: "",
  logoMobileImageDimensions: {},
  name: "",
  orderItemIndex: 0,
  phone: "",
  sponsorshipType: "",
  tournamentPackageId: "",
};

const newInProgressSponsor = (sponsorResponses, tournaemntPackage, type = "hole") => {
  const sponsor = { ...InProgressSponsor, sponsorshipType: type, tournamentPackageId : tournaemntPackage?.id };
  sponsor.id = uuidv4();
  sponsor.customFieldResponses = sponsorResponses?.map?.((pr) =>
    newCustomFieldResponse(pr)
  );
  return sponsor;
};

export const CustomFieldResponse = {
  id: "",
  value: "",
  registrationCustomField: {},
};

export const newCustomFieldResponse = (field) => {
  const f = { ...CustomFieldResponse };
  f.id = uuidv4();
  f.registrationCustomField = field;
  return f;
};

/**
 * Registration order item for each package that defines quantity
 *
 * @property {object} tournament
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} phone
 * @property {string} stripeCustomerID
 *
 *
 *
 */
export const RegistrationCustomer = {
  id: uuidv4(),
  tournament: {},
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  stripeCustomerID: "",
};

/**
 * Registration order item for each package that defines quantity
 *
 * @property {string} captcha_token
 * @property {string} captcha_action
 * @property {Boolean} is_score
 *
 *
 *
 */
export const CompanyaptchaData = {
  id: uuidv4(),
  captcha_token: "",
  captcha_action: "",
  is_score: true,
};

export const CreateAndPay = {
  id: uuidv4(),
  registrationOrder: {},
  registrationCustomer: {},
  captcha_data: {},
};

export const SPONSOR_ITEM = "sponsor-package-items"
export const TEAM_ITEM = "team-package-items"
export const PLAYER_ITEM = "player-package-items"
export const TECH_SPONSOR = "technology-sponsor-package-items"

export const addPackageToRegistrationOrder = (
  tournamentPackage,
  registrationOrder
) => {
  const {
    fieldSets,
    packageItems
  } = tournamentPackage;

  const playerResponses = fieldSets
    ?.filter?.((fs) => fs.occurrence === "per_player")
    ?.flatMap?.((cfr) => {
      return cfr?.fieldSets?.fields?.map?.((f) => {
        return { ...f, customFieldResponse: cfr };
      });
    });

  const teamResponses = fieldSets
    ?.filter?.((fs) => fs.occurrence === "per_team")
    ?.flatMap?.((cfr) => {
      return cfr?.fieldSets?.fields?.map?.((f) => {
        return { ...f, customFieldResponse: cfr };
      });
    });

  const orderResponses = fieldSets
    ?.filter?.((fs) => fs.occurrence === "per_order")
    ?.flatMap?.((cfr) => {
      return cfr?.fieldSets?.fields?.map?.((f) => {
        return { ...f, customFieldResponse: cfr };
      });
    });

  const sponsorResponses = fieldSets
    ?.filter?.((fs) => fs.occurrence === "per_sponsor")
    ?.flatMap?.((cfr) => {
      return cfr?.fieldSets?.fields?.map?.((f) => {
        return { ...f, customFieldResponse: cfr };
      });
    });


    //////////

    const playerForms = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === PLAYER_ITEM)
    

  const teamForms = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === TEAM_ITEM)

  const sponsorForms = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === SPONSOR_ITEM)

    const leaderSponsorForms = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === TECH_SPONSOR)

    //////

  let index = registrationOrder?.registrationOrderItems?.findIndex?.((oi) => {
    return oi?.tournamentPackage?.id === tournamentPackage.id;
  });

  const playerSize = registrationOrder?.tournament?.numberOfPlayers;

  if (index >= 0) {
    const items = [...registrationOrder.registrationOrderItems];
    const update = { ...items[index] };
    update.quantity += 1;
    teamForms?.forEach?.(tf => {
      for (let i = 0; i < tf?.quantity; i++) {
        const currentTeams = update.inProgressTournamentTeams ?? [];
        update.inProgressTournamentTeams = [
          ...currentTeams,
          newInProgressTeam(playerSize, teamResponses, playerResponses),
        ];
      }
    })
    
    sponsorForms?.forEach?.(sf => {
      for (let i = 0; i < sf?.quantity; i++) {
        const currentSponsors = update.inProgressSponsors ?? [];
        update.inProgressSponsors = [
          ...currentSponsors,
          newInProgressSponsor(sponsorResponses, tournamentPackage, "hole"),
        ];
      }
    })

    leaderSponsorForms?.forEach?.(sf => {
      for (let i = 0; i < sf?.quantity; i++) {
        const currentSponsors = update.inProgressSponsors ?? [];
        update.inProgressSponsors = [
          ...currentSponsors,
          newInProgressSponsor(sponsorResponses, tournamentPackage, "leaderboard"),
        ];
      }
    })
    
    playerForms?.forEach?.(pf => {
      for (let i = 0; i < pf?.quantity; i++)  {
        const currentPlayers = update.inProgressPlayers ?? [];
        update.inProgressPlayers = [
          ...currentPlayers,
          newInProgressPlayer(playerResponses, true),
        ];
      }
    })
    
    items[index] = update;
    registrationOrder.registrationOrderItems = items;
  } else {
    let item = newRegistrationOrderItem(tournamentPackage);

    teamForms?.forEach?.(tf => {
      for (let i = 0; i < tf?.quantity; i++) {
        const currentPlayers = item.inProgressTournamentTeams ?? [];
        item.inProgressTournamentTeams = [
          ...currentPlayers,
          newInProgressTeam(playerSize, teamResponses, playerResponses),
        ];
      }
    })
    
    sponsorForms?.forEach?.(sf => {
      for (let i = 0; i < sf?.quantity; i++){
        const currentSponsors = item.inProgressSponsors ?? [];
        item.inProgressSponsors = [
          ...currentSponsors,
          newInProgressSponsor(sponsorResponses, tournamentPackage, "hole"),
        ];
      }
    })

    leaderSponsorForms?.forEach?.(sf => {
      for (let i = 0; i < sf?.quantity; i++){
        const currentSponsors = item.inProgressSponsors ?? [];
        item.inProgressSponsors = [
          ...currentSponsors,
          newInProgressSponsor(sponsorResponses, tournamentPackage, "leaderboard"),
        ];
      }
    })
   
    playerForms?.forEach?.(pf => {
      for (let i = 0; i < pf?.quantity; i++){
        const currentPlayers = item.inProgressPlayers ?? [];
        item.inProgressPlayers = [
          ...currentPlayers,
          newInProgressPlayer(playerResponses, true),
        ];
      }
    })
    
    const currentItems = registrationOrder?.registrationOrderItems ?? [];
    registrationOrder.registrationOrderItems = [...currentItems, item];
    if (orderResponses?.length > 0) {
      registrationOrder.customFieldResponses = [
        ...registrationOrder.customFieldResponses,
        ...orderResponses?.map?.((fs) => {
          return newCustomFieldResponse(fs);
        }),
      ];
    }
  }
};

export const decrementOrderItem = (orderItem, registrationOrder) => {
  let item = registrationOrder.registrationOrderItems?.find?.(
    (item) => item.id === orderItem.id
  );

  const found = { ...item };

  if (found.quantity > 1) {
    found.quantity -= 1;
  }

  const {packageItems} = found.tournamentPackage

  const players = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === PLAYER_ITEM)
    
  const teams = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === TEAM_ITEM)

  const sponsors = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === SPONSOR_ITEM)

    const leaderboardSponsors = packageItems
    ?.filter?.((fs) => fs?.packageItem?.responseType === TECH_SPONSOR)

  players?.forEach?.(p => {
    const quantity = p?.quantity ?? 1
    const index = quantity - 1
    found.inProgressPlayers = [...found.inProgressPlayers]?.slice?.(index, -1);
  })
  teams?.forEach?.(t => {
    const quantity = t?.quantity ?? 1
    const index = quantity - 1
    found.inProgressTournamentTeams = [
      ...found.inProgressTournamentTeams,
    ]?.slice?.(index, -1);
  })
  sponsors?.forEach?.(s => {
    const quantity = s?.quantity ?? 1
    const index = quantity - 1
    found.inProgressSponsors = [...found.inProgressSponsors]?.slice?.(
      index,
      -1
    );
  })
  leaderboardSponsors?.forEach?.(s => {
    const quantity = s?.quantity ?? 1
    const index = quantity - 1
    found.inProgressSponsors = [...found.inProgressSponsors]?.slice?.(
      index,
      -1
    );
  })

  return found;
};

export const removeOrderItem = (orderItem, registrationOrder) => {
  let items = registrationOrder?.registrationOrderItems?.filter?.(
    (item) => item.id !== orderItem.id
  );
  registrationOrder.registrationOrderItems = items;

  const orderResponses =
    orderItem?.tournamentPackage?.fieldSets
      ?.filter?.((fs) => fs.occurrence === "per_order")
      ?.flatMap?.((cfr) => {
        return cfr?.fieldSets?.fields?.map?.((f) => {
          return { ...f, customFieldResponse: cfr };
        });
      }) ?? [];

  for (let response of orderResponses) {
    const responseIDs =
      registrationOrder?.customFieldResponses?.map?.(
        (r) => r.registrationCustomField.id
      ) ?? [];
    let index = responseIDs?.lastIndex?.(response?.id);
    registrationOrder?.customFieldResponses?.splice?.(index, 1);
  }
};

export const getOrderItemCount = (registrationOrder) => {
  const quantity = registrationOrder?.registrationOrderItems?.reduce?.(
    (total, current) => total + current?.quantity,
    0
  );
  return quantity;
};

export const getPrice = (registrationOrder) => {
  const cost = registrationOrder?.registrationOrderItems?.reduce?.(
    (total, current) => total + getOrderItemPrice(current),
    0
  );
  return cost;
};

export const getDonationPrice = (registrationOrder) => {
  const cost = registrationOrder?.donationOrderItems?.reduce?.(
    (total, current) => total + current?.amount,
    0
  );
  return cost;
};

export const getPriceFormatted = (registrationOrder) => {
  const cost = getPrice(registrationOrder);
  const donations = getDonationPrice(registrationOrder);
  return moneyWithCommas(cost + donations);
};

export const updateRegistrationOrderForm = (response, registrationOrder) => {
  const update = { ...registrationOrder };
  const cfr = response;
  const cfrs =
    registrationOrder?.customFieldResponses?.filter?.((r) => r.id !== cfr.id) ??
    [];
  update.customFieldResponses = [...cfrs, response];
  return update;
};

export const getOrderItemPrice = (registrationOrderItem) => {
  return (
    registrationOrderItem?.tournamentPackage?.cost *
    registrationOrderItem?.quantity
  );
};

export const checkCustomFieldResponses = (section, nextStep, actions) => {
  const warnings = [];
  const responses = section?.customFieldResponses ?? [];
  for (let form of responses) {
    if (form?.registrationCustomField?.required && (form.value ?? "") === "") {
      warnings.push({
        nextStep: nextStep,
        icon: (
          <FontAwesomeIcon
            style={{ ...defaultTheme.error.light }}
            icon={faExclamationCircle}
          />
        ),
        actions: actions,
      });
    }
  }
  return warnings;
};

export const isPlayerValid = (player, orderItem) => {
  if(player.useRegistrationDetails){
    return true
  }
  if (
    orderItem?.tournamentPackage?.requirePlayerFirstName &&
    (player?.firstName ?? "") === ""
  ) {
    return false;
  }
  if (
    orderItem?.tournamentPackage?.requirePlayerLastName &&
    (player?.lastName ?? "") === ""
  ) {
    return false;
  }
  if (
    orderItem?.tournamentPackage?.requirePlayerEmail &&
    (player?.email ?? "") === ""
  ) {
    return false;
  }
  if (
    orderItem?.tournamentPackage?.requirePlayerPhone &&
    (player?.phone ?? "") === ""
  ) {
    return false;
  }
  if (
    orderItem?.tournamentPackage?.requirePlayerGhinOrHandicap &&
    (player?.ghin ?? "") === ""
  ) {
    return false;
  }
  return true;
};

export const isPlayerIncomplete = (player) => {
  if(player.useRegistrationDetails){
    return false
  }
  if ((player?.firstName ?? "") !== "") {
    return false;
  }
  if ((player?.lastName ?? "") !== "") {
    return false;
  }
  if ((player?.email ?? "") !== "") {
    return false;
  }
  if ((player?.phone ?? "") !== "") {
    return false;
  }
  if ((player?.ghin ?? "") !== "") {
    return false;
  }
  return true;
};

export const sponsorIsValid = (sponsor) => {
  if ((sponsor?.name ?? "") === "") {
    return false;
  }
  return true;
};

export const validatePlayer = (
  player,
  currentWarnings,
  navigate,
  orderItem
) => {
  let warnings = currentWarnings ? [...currentWarnings] : [];
  warnings = [
    ...warnings,
    ...checkCustomFieldResponses(player, "Add Player Details", [
      {
        title: "Add Details",
        buttonIcon: faPen,
        type: "orange",
        onClick: () => {
          navigate(`${orderItem?.id}/player?id=${player?.id}`);
        },
        orderItemID: orderItem?.id,
        playerID: player?.id,
      },
      {
        title: "Continue",
        type: "grey",
        rightIcon: faArrowRight,
        isDisabled: true,
      },
    ]),
  ];
  if (!isPlayerValid(player, orderItem)) {
    warnings.push({
      nextStep: "Add Player Details",
      icon: (
        <FontAwesomeIcon
          style={{ ...defaultTheme.error.light }}
          icon={faExclamationCircle}
        />
      ),
      actions: [
        {
          title: "Add Details",
          buttonIcon: faPen,
          type: "orange",
          onClick: () => {
            navigate(`${orderItem?.id}/player?id=${player?.id}`);
          },
          orderItemID: orderItem?.id,
          playerID: player?.id,
        },
        {
          title: "Continue",
          type: "grey",
          rightIcon: faArrowRight,
          isDisabled: true,
        },
      ],
    });
  }
  return warnings;
};

export const validatePlayers = (
  players,
  currentWarnings,
  navigate,
  orderItem
) => {
  let warnings = [...currentWarnings];
  for (let player of players) {
    warnings = [
      ...warnings,
      ...validatePlayer(player, warnings, navigate, orderItem),
    ];
  }
  return warnings;
};

export const validateTeam = (team, currentWarnings, navigate, orderItem) => {
  let warnings = currentWarnings ? [...currentWarnings] : [];
  const teamPlayers = team.inProgressPlayers ?? [];
  for (let player of teamPlayers) {
    warnings = [
      ...warnings,
      ...checkCustomFieldResponses(player, "Add Team Details", [
        {
          title: "Add Details",
          buttonIcon: faPen,
          type: "orange",
          onClick: () => {
            navigate(`${orderItem?.id}/team?id=${team?.id}`);
          },
          orderItemID: orderItem?.id,
          teamID: team?.id,
        },
        {
          title: "Continue",
          rightIcon: faArrowRight,
          type: "grey",
          isDisabled: true,
        },
      ]),
    ];
    if (!isPlayerValid(player, orderItem)) {
      warnings.push({
        nextStep: "Add Team Details",
        icon: (
          <FontAwesomeIcon
            style={{ ...defaultTheme.error.light }}
            icon={faExclamationCircle}
          />
        ),
        actions: [
          {
            title: "Add Details",
            buttonIcon: faPen,
            type: "orange",
            onClick: () => {
              navigate(`${orderItem?.id}/team?id=${team?.id}`);
            },
            orderItemID: orderItem?.id,
            teamID: team?.id,
            playerID: player?.id,
          },
          {
            title: "Continue",
            rightIcon: faArrowRight,
            type: "grey",
            isDisabled: true,
          },
        ],
      });
    }
  }
  warnings = [
    ...warnings,
    ...checkCustomFieldResponses(team, "Add Team Details", [
      {
        title: "Add Details",
        buttonIcon: faPen,
        type: "orange",
        onClick: () => {
          navigate(`${orderItem?.id}/team?id=${team?.id}`);
        },
        orderItemID: orderItem?.id,
        teamID: team?.id,
      },
      {
        title: "Continue",
        type: "grey",
        rightIcon: faArrowRight,
        isDisabled: true,
      },
    ]),
  ];
  return warnings;
};

export const validateTeams = (teams, currentWarnings, navigate, orderItem) => {
  let warnings = [...currentWarnings];
  for (let team of teams) {
    warnings = [
      ...warnings,
      ...validateTeam(team, currentWarnings, navigate, orderItem),
    ];
  }
  return warnings;
};

export const validateSponsor = (
  sponsor,
  currentWarnings,
  navigate,
  orderItem
) => {
  let warnings = currentWarnings ? [...currentWarnings] : [];
  if (!sponsorIsValid(sponsor)) {
    warnings = [
      ...warnings,
      {
        nextStep: "Add Sponsor Details",
        icon: (
          <FontAwesomeIcon
            style={{ ...defaultTheme.error.light }}
            icon={faExclamationCircle}
          />
        ),
        actions: [
          {
            title: "Add Details",
            type: "orange",
            buttonIcon: faPen,
            onClick: () => {
              navigate(`${orderItem?.id}/sponsor?id=${sponsor?.id}`);
            },
            orderItemID: orderItem?.id,
            sponsorID: sponsor?.id,
          },
          {
            title: "Continue",
            rightIcon: faArrowRight,
            type: "grey",
            isDisabled: true,
          },
        ],
      },
    ];
  }
  warnings = [
    ...warnings,
    ...checkCustomFieldResponses(sponsor, "Add Details", [
      {
        title: "Add Details",
        type: "orange",
        onClick: () => {
          navigate(`${orderItem.id}/sponsor?id=${sponsor?.id}`);
        },
        orderItemID: orderItem?.id,
        sponsorID: sponsor?.id,
      },
      {
        title: "Continue",
        rightIcon: faArrowRight,
        type: "grey",
        isDisabled: true,
      },
    ]),
  ];
  return warnings;
};

export const validateSponsors = (
  sponsors,
  currentWarnings,
  navigate,
  orderItem
) => {
  let warnings = [...currentWarnings];
  for (let sponsor of sponsors) {
    warnings = [
      ...warnings,
      ...validateSponsor(sponsor, currentWarnings, navigate, orderItem),
    ];
  }
  return warnings;
};

export const validateOrder = (registrationOrder, navigate) => {
  let warnings = [];
  //check order forms

  const items = registrationOrder?.registrationOrderItems ?? [];
  for (let orderItem of items) {
    const players = orderItem?.inProgressPlayers ?? [];
    const teams = orderItem?.inProgressTournamentTeams ?? [];
    const sponsors = orderItem?.inProgressSponsors ?? [];

    warnings = validatePlayers(players, warnings, navigate, orderItem);
    warnings = validateTeams(teams, warnings, navigate, orderItem);
    warnings = validateSponsors(sponsors, warnings, navigate, orderItem);
  }

  warnings = [
    ...warnings,
    ...checkCustomFieldResponses(registrationOrder, "Add Form Response", [
      {
        title: "Add Details",
        buttonIcon: faPen,
        type: "orange",
        onClick: () => {
          navigate("orderForm");
        },
      },
      {
        title: "Continue",
        rightIcon: faArrowRight,
        type: "grey",
        isDisabled: true,
      },
    ]),
  ];
  return warnings;
};

export const roundUpCost = (totalCost) => {
  const cost = Number(totalCost);
  const roundedUp = Math.ceil(cost);
  return roundedUp - cost;
};

export const getRegistrationSummary = (
  registrationOrder,
  price,
  type,
  showFee
) => {
  const summaryType = type ?? "Subtotal";

  return `${summaryType}: ${price} | ${summarize(registrationOrder, showFee)}`;
};

export const isTeamInfoRequired = (inProgressTeam, orderItem) => {
  return (
    orderItem?.tournamentPackage?.requirePlayerFirstName ||
    orderItem?.tournamentPackage?.requirePlayerLastName ||
    orderItem?.tournamentPackage?.requirePlayerEmail ||
    orderItem?.tournamentPackage?.requirePlayerPhone ||
    orderItem?.tournamentPackage?.requirePlayerGhinOrHandicap ||
    checkCustomFieldResponses(inProgressTeam)?.length > 0
  );
};

export const isPlayerInfoRequired = (inProgressPlayer, orderItem) => {
  return (
    orderItem?.tournamentPackage?.requirePlayerFirstName ||
    orderItem?.tournamentPackage?.requirePlayerLastName ||
    orderItem?.tournamentPackage?.requirePlayerEmail ||
    orderItem?.tournamentPackage?.requirePlayerPhone ||
    orderItem?.tournamentPackage?.requirePlayerGhinOrHandicap ||
    checkCustomFieldResponses(inProgressPlayer)?.length > 0
  );
};

export const isSponsorInfoRequired = (inProgressSponsor) => {
  return true;
};

export const LOCAL_STORAGE_ORDER = "golfstatusOrder";

export const saveOrderToLocalStorage = (registrationOrder) => {
  const orderString = localStorage?.getItem?.(LOCAL_STORAGE_ORDER) ?? "";
  const orders = orderString !== "" ? JSON.parse(orderString) : [];
  const currentOrderIndex = orders?.findIndex?.(
    (o) => o.id === registrationOrder.id
  );
  if (currentOrderIndex !== -1) {
    orders[currentOrderIndex] = registrationOrder;
  } else {
    orders.push(registrationOrder);
  }
  try{
    localStorage.setItem(LOCAL_STORAGE_ORDER, JSON.stringify(orders));
  }
  catch{
    console.log("unable to save to local storage")
  }
  
};

export const getOrderFromLocalStorage = (tournament) => {
  const orderString = localStorage?.getItem?.(LOCAL_STORAGE_ORDER) ?? "";
  if (orderString !== "") {
    const orders = JSON.parse(orderString) ?? [];
    if (orders.length > 0) {
      const order = orders?.find?.(
        (order) => order?.tournament?.id === tournament?.id
      );
      if (order) {
        return order;
      }
    }
  }
  return { ...RegistrationOrder, tournament };
};

export const getOrderFromLocalStorageByID = (orderID) => {
  const orderString = localStorage?.getItem?.(LOCAL_STORAGE_ORDER) ?? "";
  if (orderString !== "") {
    const orders = JSON.parse(orderString) ?? [];
    if (orders.length > 0) {
      const order = orders?.find?.(
        (order) => order?.id === orderID
      );
      if (order) {
        return order;
      }
    }
  }
  return { ...RegistrationOrder };
};


export const deleteOrderFromLocalStorage = (registrationOrder) => {
  const orderString = localStorage?.getItem?.(LOCAL_STORAGE_ORDER) ?? "";
  const orders = orderString !== "" ? JSON.parse(orderString) : [];
  const newOrders = orders?.filter?.((o) => o.id !== registrationOrder.id);
  try{
    localStorage.setItem(LOCAL_STORAGE_ORDER, JSON.stringify(newOrders));
  }
  catch{
    console.log("unable to save to local storage")
  }
  
};
