import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  defaultPadding,
  GSPageSection,
  defaultLayouts,
  GSEmptyList,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeams,
  selectTournamentTeams,
} from "../../../reducers/tournamentSlice";
import "./event-registrants.scss";
import { useHeaderStyle, useSectionStyles } from "../../../hooks/themeHooks";
import { eventBaseRoute, TOURNAMENT_ID } from "../../../routes/event";
import { getRouting } from "../../../helpers/Converters";
import RegisterNowWidget from "../../widgets/register-now-widget";
import TournamentRegistrantsWidget from "../../widgets/tournament-registrants-widget";
import AvailablePackagesWidget from "../../widgets/available-packages-widget";
import PageHeader from "../../page-header";
import { useNavigationButtons } from "../../../hooks/pageHooks";
import { usePermissions } from "../../../hooks/permissionHooks";
import { useTournament, useTournamentPackages } from "../../../hooks/tournamentHooks";

const EventRegistrants = (props) => {
  const tournamentTeams = useSelector(selectTournamentTeams)
  const {accessiblePackages} = useTournamentPackages()
  const tournament = useTournament();

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID, "registrants", "*"]);
  const match = useMatch(route);

  const tournamentID = tournament?.id;

  const dispatch = useDispatch();
  const { headerStyle } = useHeaderStyle();

  const { style, defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const getPageActions = useNavigationButtons("registrants");

  const permissions = usePermissions();

  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentTeams(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    //
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getItemList = () => {
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Registrants" />
        {permissions.packagesEnabled() && accessiblePackages?.length > 0 ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<RegisterNowWidget />]}
          />
        ) : null}

        {tournamentTeams?.length > 0 ? (
          <GSPageSection
            bodyStyle={{ ...defaultPadding.noPad }}
            style={style}
            title="All Registrants"
            body={[<TournamentRegistrantsWidget />]}
          />
        ) : (
          <GSPageSection
            body={[
              <GSEmptyList
                style={{
                  ...defaultPadding.emptyListPad,
                  ...defaultLayouts.fullWidth,
                }}
                title="No Registrants Yet"
                detail={`${tournament?.name} does not have any registrants.`}
              />,
            ]}
          />
        )}

        {permissions.packagesEnabled() && accessiblePackages?.length > 0 ? (
          <GSPageSection
            title="Registration Packages"
            bodyStyle={{
              ...defaultSectionStyle.bodyStyle,
              ...defaultPadding.noPad,
            }}
            body={[<AvailablePackagesWidget />]}
          />
        ) : null}
      </div>
    );
  };

  return (
    <event-registrants>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
      ></GSListPage>
    </event-registrants>
  );
};

export default EventRegistrants;
