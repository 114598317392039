
import React from "react";
import { GSForm, GSSidePanelPage, useFormDataValidation } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./package-access-form.scss"

import { useNotificationAction, useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getAccessListForm } from "../../../../forms/AccessListForm";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications, getTournamentAccessCode, selectAccessListCredentials, selectNotifications, selectPublicTournament, setAccessListCredentials } from "../../../../reducers/tournamentSlice";
import { FULFILLED } from "../../../../app/api";
import { addNotification } from "../../../../reducers/appSlice";

//Name the component
const PackageAccessForm = (props) => {

  const accessListCredentials = useSelector(selectAccessListCredentials)
  const tournament = useSelector(selectPublicTournament)
  const dispatch = useDispatch()

  const [context, , setIsValid] = useFormDataValidation({
    initialValid: true,
    data: accessListCredentials,
    setData: (update) => {dispatch(setAccessListCredentials(update))}
  })

  const navigate = useNavigate()
  const notifications = useSelector(selectNotifications).filter?.(n => n.id === "getTournamentAccessCode")

  useNotificationAction(FULFILLED, notifications, (notification) => {
    dispatch(clearNotifications())
    dispatch(addNotification(notification))
    navigate(-1)
  }, "getTournamentAccessCode" )

  const save = () => {
    const filter = {...accessListCredentials, tournamentID : tournament?.id}
    dispatch(getTournamentAccessCode(filter))
    setIsValid(false)
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Apply Credentials",
        //isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Access List Credentials`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle="Access List Credentials" formSections={getAccessListForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <package-access-form>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </package-access-form>
  );
};

//export the new namet
export default PackageAccessForm;