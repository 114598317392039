import {
  defaultBorders,
  defaultLayouts,
  defaultPadding,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  addPackageToOrder,
  getTournamentPackageFieldSets,
  selectRegistrationOrder,
  updateCurrentPackage,
} from "../../../../reducers/tournamentSlice";
import PackageLineItem from "./package-line-item";
import { useTheme } from "../../../../hooks/themeHooks";
import { faCartPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { addNotification } from "../../../../reducers/appSlice";
import { createNotification } from "../../../../app/api";
import { mediumDelay } from "../../../../helpers/JSONapi";
import { getRouting } from "../../../../helpers/Converters";
import { eventBaseRoute } from "../../../../routes/event";
import { useTournament } from "../../../../hooks/tournamentHooks";

export const createAddToCartNotification = (
  tournament,
  registrationOrder,
  navigate
) => {
  return createNotification(
    "Package has been added to your cart",
    faCartPlus,
    "success",
    "saved",
    mediumDelay,
    [
      {
        id: 0,
        title: "View Cart",
        type: "secondary",
        style: {},
        actionClick: () => {
          navigate(
            getRouting(eventBaseRoute, [
              tournament?.publicUrl,
              "cart",
              registrationOrder.id,
            ])
          );
        },
      },
    ]
  );
};

const PackageList = (props) => {
  const { packages } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [, theme, getStyle] = useTheme();

  const tournament = useTournament();
  const registrationOrder = useSelector(selectRegistrationOrder);

  const viewPackage = (tournamentPackage) => {
    dispatch(updateCurrentPackage(tournamentPackage));
    navigate(`${tournamentPackage.id}?${searchParams.toString()}`);
  };

  const getPackageStyle = (tp) => {
    return tp.remainingPackages > 0
      ? getStyle(theme.surfaceVariant)
      : { ...getStyle(theme.surfaceVariant) };
  };

  const getPackageAction = (tp) => {
    return tp.remainingPackages > 0
      ? {
          title: "Add To Cart",
          type: "",
          buttonIcon: faPlus,
          style: { ...getStyle(theme.tertiaryContainer), flexGrow: 1 },
          onClick: (e) => {
            addPackageToCart(tp);
            e?.stopPropagation?.();
          },
        }
      : {
          title: "sold out",
          type: "black pill secondary",
        };
  };

  const addPackageToCart = (currentPackage) => {
    dispatch(
      addNotification(
        createAddToCartNotification(tournament, registrationOrder, navigate)
      )
    );
    dispatch(getTournamentPackageFieldSets(currentPackage?.id)).then((response) => {
      const p = {...currentPackage}
      p.fieldSets = response?.payload?.fieldSets
      p.packageItems = response?.payload?.packageItems
      dispatch(addPackageToOrder(p));
    })
    
  };

  return (
    <package-list>
      <GSItemList
        style={{ ...defaultLayouts.fullWidth }}
        type="vertical selectable medium-large-gap"
        items={packages}
        itemSelected={(p) => {
          viewPackage(p);
        }}
        listItem={(tp) => (
          <PackageLineItem
            className="hover-color-shift"
            style={{
              ...defaultLayouts.fullWidth,
              ...defaultPadding.xLargePad,
              ...getPackageStyle(tp),
              ...defaultBorders.mediumLargeBorderRadius,
            }}
            {...tp}
            action={getPackageAction(tp)}
          />
        )}
      />
    </package-list>
  );
};

export default PackageList;
