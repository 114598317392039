export const getAccessListForm = (context) => {
  return [
    {
      type: "vertical xx-large-gap",
      fields: [
        { label: "Access Code", isEditable: true, required: true, ...context.setBinding?.("accessIdentifier"), placeholder: "Access Code" },
        { label: "Email Address", isEditable: true, required: true, ...context.setBinding("accessEmail"), placeholder: "Email Address" },
      ],
    },
  ];
};
