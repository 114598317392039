import { useDispatch, useSelector } from "react-redux";
import {
  getPublicTournamentDetails,
  getTournamentPackages,
  selectLoading,
  selectPublicTournament,
  selectTournamentAccessCodes,
  selectTournamentPackages,
} from "../reducers/tournamentSlice";
import { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { eventBaseRoute, getProp, TOURNAMENT_ID } from "../routes/event";
import { getRouting } from "../helpers/Converters";
import { sortPackages } from "../components/events/event/event-packages";
import { selectUserPaymentOptions } from "../reducers/userSlice";
import { BANK_ACCOUNT, CREDIT_CARD } from "../components/widgets/payment-select-widget";
import { ACTIVE } from "./permissionHooks";

export const useTournament = () => {
  const tournament = useSelector(selectPublicTournament);
  const loading = useSelector(selectLoading).some?.(c => c === "getPublicTournamentDetails")

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID, "*"]);

  const match = useMatch(route);

  const id = getProp(match?.params, TOURNAMENT_ID);

  const dispatch = useDispatch();

  useEffect(() => {
    if((id !== tournament?.id && id !== tournament.publicUrl) && !loading){
     dispatch(getPublicTournamentDetails(id));
    }
  }, [id, dispatch, tournament?.id, tournament?.publicUrl, loading]);

  const event = tournament?.id ? tournament: { publicUrl: id};
  return event
};

export const useTournamentPackages = () => {
  const tournament = useSelector(selectPublicTournament);
  const tournamentPackages = useSelector(selectTournamentPackages);
  const accessCodes = useSelector(selectTournamentAccessCodes) ?? [];
  const accessGroupID = accessCodes?.[0]?.accessGroup?.id

  const dispatch = useDispatch();

  const tournamentID = tournament?.id;

  const accessiblePackages = tournamentPackages?.filter?.(
    (p) => {
      const now = new Date()
      const activate = p.activateOn ? new Date(p?.activateOn) : now
      const deactivate =p.deactivateOn ? new Date(p?.deactivateOn) : now
      const past = deactivate < now
      const future = activate > now
      const groupAccess = !p?.hasAccessGroup || p?.accessGroup?.id === accessGroupID
      const active = p?.state === ACTIVE && !past && !future
      const notSoldOut =  p?.remainingPackages > 0
      return groupAccess && active && notSoldOut
    }
  );

  const hasAccessPackages = tournamentPackages?.some?.((p) => p.hasAccessGroup);

  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentPackages(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const {
    teamPackages,
    playerPackages,
    sponsorPackages,
    addonPackages,
  } = sortPackages(accessiblePackages);

  return {
    tournamentPackages,
    accessiblePackages,
    teamPackages,
    playerPackages,
    sponsorPackages,
    addonPackages,
    hasAccessPackages
  };
};


export const VERIFIED = "verified"

export const usePaymentOptions = () => {
  const savedPaymentOptions = useSelector(selectUserPaymentOptions);
  const tournament = useSelector(selectPublicTournament)

  const getAvailablePayments = () => {
    if(tournament.escrowOrg){
      return savedPaymentOptions?.filter(po => po.hasGlobal && po.status === VERIFIED )
    }
    else {
      return savedPaymentOptions?.filter(po => po.hasStripe && po.status === VERIFIED)
    }
  }

  const availablePayments = getAvailablePayments()

  const availableCreditCards = availablePayments?.filter?.(p => p.paymentType === CREDIT_CARD)
  const availableBanks = availablePayments?.filter?.(p => p.paymentType === BANK_ACCOUNT)

  return {savedPaymentOptions, availablePayments, availableCreditCards, availableBanks}

}