import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { useTournament } from "../../hooks/tournamentHooks";

const RegisterNowWidget = (props) => {
  const { style } = props;

  const tournament = useTournament()

  const navigate = useNavigate();

  const titleSection = {
    gap: "medium-large-gap",
    sectionItems: [
      {
        type: "headline-2",
        value: "Register to Play Today!",
      },
      {
        type: "secondary",
        value: `Teams spots are still available for ${tournament.name}`,
      },
    ],
  };

  const actionSection = {
    sectionItems: [
      {
        type: "mobile-stretch",
        value: (
          <GSButton
            style={{ flexGrow: 1 }}
            title="Register Now"
            rightIcon={faArrowRight}
            type="black"
            onClick={() => {
              navigate(
                getRouting(eventBaseRoute, [
                  tournament?.publicUrl,
                  "packages?view=team&view=player",
                ])
              );
            }}
          />
        ),
      },
    ],
  };

  const titleColumn = {
    fit: "stretch",
    sections: [titleSection],
  };

  const actionColumn = {
    type: "mobile-stretch",
    sections: [actionSection],
  };

  const columns = [titleColumn, actionColumn];

  return (
    <GSInfoGroup
      listType="mobile-vertical"
      style={style}
      dataGroups={columns}
    />
  );
};

export default RegisterNowWidget;
