import { useSelector } from "react-redux";
import {
  selectPublicTournament,
  selectRegistrationOrder,
  selectTournamentPaymentSettings,
} from "../reducers/tournamentSlice";
import { CUSTOMER_INVOICE, PAY_AT_COURSE } from "../forms/CartSections";

export const DRAFT = "draft";
export const ACTIVE = "active";
export const FINAL = "final";

export const usePermissions = () => {
  const tournamentPaymentSettings =
    useSelector(selectTournamentPaymentSettings) ?? {};
  const tournament = useSelector(selectPublicTournament) ?? {};
  const registrationOrder = useSelector(selectRegistrationOrder);

  const organization = tournament?.organization ?? {};

  //org settings
  const orgPaymentsActive = organization?.paymentsActive ?? false;
  const orgIsEscrow = organization?.isEscrowAccount ?? false;
  //tournament payment settings
  const tournamentPaymentsActive =
    tournamentPaymentSettings?.onlinePayments ?? false;
  const organizationPaysPlatformFee =
    tournamentPaymentSettings?.organizationPaysPlatformFee ?? false;
  const allowRegistrantCoverFees =
    tournamentPaymentSettings?.allowRegistrantCoverFees ?? false;
  const allowDonations = tournamentPaymentSettings?.donations ?? false;
  const allowDiscountCodes =
    tournamentPaymentSettings?.allowDiscountCodes ?? false;
  const donationAmountActive =
    tournamentPaymentSettings?.donationAmountActive ?? false;
  const invoicesAllowed = tournamentPaymentSettings?.invoicesAllowed ?? false;
  const invoicesActive = tournamentPaymentSettings?.invoicesActive ?? false;
  const payAtCourse = tournamentPaymentSettings?.payAtCourse ?? false;
  const canRoundUp = tournamentPaymentSettings?.roundUp ?? false;
  const platformFeeEnabled =
    tournamentPaymentSettings?.platformFeeEnabled ?? false;
  const donationGoal = tournamentPaymentSettings?.donationGoal ?? 0;
  //tournament settings
  const registrationClosed = tournament?.registrationClosed ?? false;
  const isDonationsActive = tournament?.isDonationsActive ?? false;
  const isRegistrationActive = tournament?.isRegistrationActive ?? false;
  const publicRegistration = tournament?.openRegistration ?? false;
  const registrationCloseDate = tournament?.registrationClose ?? "";
  const teamRegistrationClosed = tournament?.teamRegistrationClosed ?? false;
  const teamsAvailable = tournament?.teamsAvailable ?? false;
  const tournamentState = tournament?.state ?? "";
  const hasRounds = tournament?.numberOfRounds > 0;
  const tournamentEscrowOrg = tournament?.escrowOrg;
  const numberOfTeams = tournament?.numberOfTeams;
  const numberRegisteredTeams = tournament?.numberRegisteredTeams;
  const hasWaitList = tournament?.hasWaitList

  //tournamentPromotion
  const hideRegistrants =
    tournament?.tournamentPromotion?.hideRegistrants ?? false;
  const hideSponsorships =
    tournament?.tournamentPromotion?.hideSponsorship ?? false;
  const hideLeaderboards =
    tournament?.tournamentPromotion?.hideLeaderboards ?? false;
    const hideCourseDetails =
    tournament?.tournamentPromotion?.hideCourseDetails ?? false;

  const packagesEnabled = () => {
    return (
      !registrationClosed && publicRegistration && tournamentState === ACTIVE
    );
  };

  const donationsActive = () => {
    return isDonationsActive && tournamentState !== DRAFT && registrationOrder.paymentType !== PAY_AT_COURSE && registrationOrder.paymentType !== CUSTOMER_INVOICE;
  };

  const showDonationAmount = () => {
    return donationAmountActive && donationGoal !== 0;
  };

  const creditCardPaymentsEnabled = () => {
    return tournamentPaymentsActive && tournamentState !== DRAFT;
  };

  const bankAccountPaymentsAEnabled = () => {
    return tournamentPaymentsActive && tournamentState !== DRAFT ;
  };

  const invoicesEnabled = () => {
    return invoicesAllowed && invoicesActive;
  };

  const feesEnabled = (order) => {
    const regOrder = order ?? registrationOrder
    const noPaymentFee = regOrder?.paymentType === PAY_AT_COURSE || regOrder?.paymentType === CUSTOMER_INVOICE
    const settingsEnabled = platformFeeEnabled && (!organizationPaysPlatformFee || allowRegistrantCoverFees);
    return settingsEnabled && !noPaymentFee
  };

  const hideFees = () => {
    return !allowRegistrantCoverFees || !organizationPaysPlatformFee
  }

  const escrowActive = () => {
    return tournamentEscrowOrg
  }

  const waitListEnabled = () => {
    return !playerPackagesEnabled() && hasWaitList
  }

  const playerPackagesEnabled = () => {
    return numberRegisteredTeams < numberOfTeams
  }

  return {
    orgPaymentsActive,
    orgIsEscrow,
    tournamentPaymentsActive,
    organizationPaysPlatformFee,
    allowRegistrantCoverFees,
    allowDonations,
    allowDiscountCodes,
    donationAmountActive,
    invoicesAllowed,
    invoicesActive,
    payAtCourse,
    canRoundUp,
    platformFeeEnabled,
    registrationClosed,
    isDonationsActive,
    isRegistrationActive,
    publicRegistration,
    registrationCloseDate,
    teamRegistrationClosed,
    teamsAvailable,
    tournamentState,
    hasRounds,
    hideLeaderboards,
    hideRegistrants,
    hideSponsorships,
    hideCourseDetails,
    tournamentEscrowOrg,
    packagesEnabled,
    donationsActive,
    creditCardPaymentsEnabled,
    bankAccountPaymentsAEnabled,
    invoicesEnabled,
    feesEnabled,
    showDonationAmount,
    hideFees,
    escrowActive,
    waitListEnabled,
    playerPackagesEnabled
  };
};
