import { faExternalLinkSquare } from "@fortawesome/free-solid-svg-icons";
import {
  GSImageEditor,
  GSSelect,
  defaultLayouts,
  validations,
} from "golfstatus_react_components";
import PackageLineItem from "../components/events/event/packages/package-line-item";
import SponsorLogoSelect from "../components/widgets/sponsor-logo-select";
import MobileSponsorLogoSelect from "../components/widgets/mobile-sponsor-logo-select";
import { openExternalLink } from "../helpers/Utilities";

export const getPackageForm = (context) => {
  const packageSection = {
    fields: [
      {
        style: { ...defaultLayouts.fullWidth },
        value: (
          <PackageLineItem
            style={{
              ...defaultLayouts.fullWidth,
            }}
            headerType="headline-1"
            {...context?.getData?.()}
            action={context.getPackageAction()}
          />
        ),
      },
    ],
  };

  const form = [];

  if (context?.getData?.()?.packageType === "sponsor") {
    //form.push(sponsorInformationSection(context));
  }
  if (context?.getData?.()?.packageType === "registration") {
    //form.push(teamInformationSection(context));
  }

  if (context?.getData?.()?.packageType === "individual") {
    //form.push(playerInformationSection(context));
  }
  if (context?.getData?.()?.packageType === "general") {
    //form.push(sponsorInformationSection(context));
  }

  if (context?.getData?.()?.fieldSets?.length > 0) {
    //form.push(fieldSetSection(context));
  }

  form.unshift?.(packageSection);

  return form;
};

/**
 *
 * @param {object} context
 * @returns {Array} Form sections array
 *
 */
export const getPlayerForm = (context) => {
  const players = context?.getPlayers?.();

  const playersForms =
    players?.flatMap?.((p, index) => {
      return playerInformationSection(context, p, index);
    }) ?? [];

  const form = [...playersForms];

  return form;
};

/**
 *
 * @param {object} context
 * @returns {Array} Form sections array
 *
 */
export const getTeamForm = (context) => {
  const teams = context?.getTeams?.();

  const teamsForms =
    teams?.flatMap?.((t) => {
      return teamInformationSections(t, context);
    }) ?? [];

  const form = [...teamsForms];

  return form;
};

/**
 *
 * @param {object} context
 * @returns {Array} Form sections array
 *
 */
export const getSponsorForm = (context) => {
  const sponsors = context?.getSponsorData?.();

  const sponsorForms =
    sponsors?.flatMap?.((s) => {
      return sponsorInformationSections(s, context, s.id);
    }) ?? [];

  const form = [...sponsorForms];

  return form;
};

/**
 *
 * @param {object} context
 * @returns {Array} Form sections array
 *
 */
export const getOrderForm = (context) => {
  const forms = [...getCustomFieldSets(context?.getData?.(), context)];

  return [
    {
      type: "vertical xx-large-gap",
      gridColumns: 3,
      fields: forms.flatMap?.((pf) => pf),
    },
  ];
};

/**
 *
 * @param {InProgressTeam} team
 * @param {object} context
 * @returns
 */
const teamInformationSections = (team, context) => {
  let sections =
    team.inProgressPlayers?.map?.((p, index) => {
      return playerInformationSection(context, p, index);
    }) ?? [];

  if (team?.customFieldResponses?.length > 0) {
    sections.push(additionalInformationSection(team, context, team.id));
  }

  return sections;
};

/**
 *
 * @param {InProgressSponsor} sponsor
 * @param {object} context
 * @returns
 */
const sponsorInformationSections = (sponsor, context) => {
  let sections = [
    sponsorInformationSection(context, sponsor.id),
    sponsorImagesSection(context, sponsor.id),
    sponsorInformationContactSection(context, sponsor.id),
  ];

  if (sponsor?.customFieldResponses?.length > 0) {
    sections.push(additionalInformationSection(sponsor, context, sponsor.id));
  }

  return sections;
};

/**
 *
 * @param {object} section the section that is holding the custom field responses [inProgressTeam, inProgressSponsor]
 * @param {object} context
 * @returns
 */
const additionalInformationSection = (section, context, id) => {
  const forms = [
    ...getCustomFieldSets(section.customFieldResponses, context, id),
  ];

  return {
    title: "Additional Information",
    type: "vertical xx-large-gap",
    gridColumns: 3,
    fields: forms.flatMap?.((pf) => pf),
  };
};

/**
 *
 * @param {object} context
 * @param {InProgressPlayer} inProgressPlayer
 * @returns
 */
const playerInformationSection = (context, inProgressPlayer, index) => {
  const nonContactFields = [
    {
      label: "First Name",
      isEditable: true,
      placeholder: "First Name",
      ...context.setBinding("firstName", inProgressPlayer?.id),
    },
    {
      label: "Last Name",
      isEditable: true,
      placeholder: "Last Name",
      ...context.setBinding("lastName", inProgressPlayer?.id),
    },
    {
      label: "Email Address",
      isEditable: true,
      placeholder: "Email Address",
      ...context.setBinding("email", inProgressPlayer?.id),
      validation: {
        isValid: validations.validEmail,
        invalidLabel: "Must be a valid email.",
      },
    },
    {
      label: "Phone Number",
      isEditable: true,
      placeholder: "Phone Number",
      ...context.setBinding("phone", inProgressPlayer?.id),
    },
    {
      label: "Player Notes",
      isEditable: true,
      placeHolder: "Player Notes",
      type: "text-area",
      ...context.setBinding("notes", inProgressPlayer?.id),
    },
  ];

  const contactFields = [
    { value: context?.contactDetails?.firstName, label: "First Name" },
    { value: context?.contactDetails?.lastName, label: "Last Name" },
    { value: context?.contactDetails?.email, label: "email Address" },
    { value: context?.contactDetails?.phone, label: "phone number" },
    {
      label: "Player Notes",
      isEditable: true,
      placeHolder: "Player Notes",
      type: "text-area",
      ...context.setBinding("notes", inProgressPlayer?.id),
    },
  ];

  const fields = inProgressPlayer?.useRegistrationDetails
    ? contactFields
    : nonContactFields;

  if (inProgressPlayer?.customFieldResponses?.length > 0) {
    fields.push(
      ...getCustomFieldSets(
        inProgressPlayer.customFieldResponses,
        context,
        inProgressPlayer.id
      )
    );
  }

  return {
    title: `Player ${index + 1} Details`,
    sectionActions: [
      {
        actionType: "toggle",
        pageActionProps: {
          value: inProgressPlayer?.useRegistrationDetails,
          descriptionStyle: { textWrap: "noWrap" },
          rowReverse: true,
          falseDescription: "Use Contact Details",
          trueDescription: "Use Contact Details",
          type: "no-wrap",
          onClick: (value) => {
            context.updateTeamPlayerData(
              !inProgressPlayer?.useRegistrationDetails,
              "useRegistrationDetails",
              inProgressPlayer?.id
            );
          },
        },
      },
    ],
    type: "vertical xx-large-gap mobile-vertical",
    gridColumns: 2,
    fields: fields,
  };
};

const getCustomFieldSets = (customFieldResponses, context, id) => {
  const additionalFields = customFieldResponses;

  const additionalForms =
    additionalFields?.map?.((field, index) => {
      let formField = {
        style: { gridColumn: "1 / span 2" },
        label: ` ${field?.registrationCustomField?.name} `,
        isEditable: true,
        placeholder:
          field?.registrationCustomField?.placeholder ??
          field?.registrationCustomField?.name,
        value: field?.value,
        onChange: (e) => {
          let update = { ...field };
          update.value = e.target?.value;
          context?.updateData?.(update, index, id);
        },
        failedValidation: context?.validationFailed,
        required: field?.registrationCustomField?.required,
      };
      if (field?.registrationCustomField?.options?.length > 0) {
        formField.value = (
          <GSSelect
            options={field?.registrationCustomField?.options?.map?.(
              (option) => {
                return { label: option, value: option };
              }
            )}
            selectedOption={
              (field?.value ?? "") !== "" ? { label: field?.value } : null
            }
            placeholder={
              field?.registrationCustomField?.placeholder ??
              field?.registrationCustomField?.name
            }
            onChange={(option) => {
              let update = { ...field };
              update.value = option.value;
              context?.updateData?.(update, index, id);
            }}
          />
        );
        formField.validation = {
          isValid: () => {
            const valid =
              !field?.registrationCustomField?.required ||
              (field?.value ?? "") !== "";
            return valid;
          },
          invalidLabel: "This field is required",
        };
        formField.isEditable = true;
        formField.customView = true;
        formField.failedValidation = context?.validationFailed;
        formField.required = field?.registrationCustomField?.required;
      }
      return formField;
    }) ?? [];

  return additionalForms;
};

// const fieldSetSection = (context) => {
//   const fieldSets = context?.getData?.().fieldSets;
//   const fields = fieldSets?.flatMap?.((fs) => fs?.fieldSets?.fields);
//   return {
//     title: "Additional Information",
//     type: "vertical xx-large-gap",
//     fields: fields?.map?.((f) => {
//       return { label: f?.name, required: f?.required, isEditable: true };
//     }),
//   };
// };

const sponsorInformationSection = (context, id) => {
  const website = context?.getSponsorData?.(id)?.website ?? "";
  return {
    type: "vertical xx-large-gap",
    fields: [
      {
        label: "Sponsor Name",
        isEditable: true,
        required: true,
        placeholder: "Sponsor Name",
        ...context?.setSponsorBinding?.("name", id),
      },
      {
        label: "Sponsor Website",
        isEditable: true,
        placeholder: "Sponsor Website",
        rightIcon: faExternalLinkSquare,
        rightIconClick: () => {
          if (website !== "") {
            openExternalLink(website);
          }
        },
        ...context?.setSponsorBinding?.("website", id),
      },
      {
        label: "Sponsor Message",
        isEditable: true,
        type: "text-area",
        rows: 5,
        placeHolder: "Sponsor Message",
        hintText:
          "This message will be shown in the sponsor details section of GolfStatus app.",
        ...context?.setSponsorBinding?.("message", id),
      },
    ],
  };
};

const sponsorImagesSection = (context, id) => {
  return {
    type: "vertical xx-large-gap",
    title: "Sponsor Images",
    fields: [
      {
        label: "Sponsor Image",
        value: (
          <SponsorLogoSelect
            sponsorID={id}
            context={{
              getData: () => context?.getSponsorData?.(id),
              updateData: (file, property) => {
                context?.updateSponsorData(file, property, id);
              },
              validationFailed: context.validationFailed,
            }}
          />
        ),
      },
      {
        label: "In-App Sponsor Image",
        value: (
          <MobileSponsorLogoSelect
            sponsorID={id}
            context={{
              getData: () => context?.getSponsorData?.(id),
              updateData: (file, property) => {
                context?.updateSponsorData(file, property, id);
              },
              validationFailed: context.validationFailed,
            }}
          />
        ),
      },
    ],
  };
};

const sponsorInformationContactSection = (context, id) => {
  return {
    title: "Sponsor Contact",
    type: "vertical xx-large-gap",
    fields: [
      {
        label: "Full Name",
        isEditable: true,
        placeholder: "Full Name",
        ...context?.setSponsorBinding?.("contactName", id),
      },
      {
        label: "Email Address",
        isEditable: true,
        placeholder: "Email Address",
        ...context?.setSponsorBinding?.("email", id),
        validation: {
          isValid: validations.validEmail,
          invalidLabel: "Must be a valid email.",
        },
      },
      {
        label: "Phone Number",
        isEditable: true,
        placeholder: "Phone Number",
        ...context?.setSponsorBinding?.("phone", id),
      },
    ],
  };
};

/**
 *
 * @param {object} context
 * @returns {Array} Form sections array
 *
 */
export const getCustomerForm = (context) => {
  const form = [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "First Name",
          isEditable: true,
          placeholder: "First Name",
          required: true,
          ...context.setBinding?.("firstName"),
        },
        {
          label: "Last Name",
          isEditable: true,
          placeholder: "Last Name",
          required: true,
          ...context.setBinding?.("lastName"),
        },
        {
          label: "Email Address",
          isEditable: true,
          placeholder: "Email Address",
          required: true,
          ...context.setBinding?.("email"),
          validation: {
            isValid: validations.validEmail,
            invalidLabel: "Must be a valid email.",
          },
        },
        {
          label: "Phone Number",
          isEditable: true,
          placeholder: "Phone Number",
          required: true,
          ...context.setBinding?.("phone"),
          validation: {
            isValid: validations.validPhone,
            invalidLabel: "Must be a valid phone number.",
          },
        },
      ],
    },
  ];

  return form;
};

export const getImageCropForm = (context, showCroppedImage, croppedImg) => {
  return [
    {
      type: "vertical",
      fields: [
        {
          value: showCroppedImage ? (
            <img className="preview-image" src={croppedImg} alt="upload" />
          ) : (
            <GSImageEditor
              source={context?.getLogo?.()}
              minWidth={600}
              minHeight={context.getMinHeight?.()}
              setCroppedDataURL={context.setCroppedDataURL}
              setCroppedBlob={context.setCroppedBlob}
              resizeEnabled={true}
              ratio={context?.getRatio?.()}
            ></GSImageEditor>
          ),
        },
      ],
    },
  ];
};
