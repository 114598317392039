import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNotificationItemInfo } from "../helpers/Converters";

//notifications types include "created", "removed", "saved"

export const useNotificationNavigation = (notificationType, notifications, url, id) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      notifications?.length === 1 &&
      notifications?.[0].result === notificationType &&
      notifications?.[0]?.id === (id ?? notifications?.[0]?.id)
    ) {
      const nav = url?.replace?.("[id]", notifications?.[0]?.resultPayload?.id);
      navigate(nav ?? url, { replace: true });
    }
  }, [notifications, navigate, url, id, notificationType]);
};

export const useNotificationAction = (notificationType, notifications, action, id) => {
  const notificationLength = notifications.length
  const firstNotification = notifications?.[0] ?? {}
  const result = firstNotification?.result ?? ""
  const notificationID = firstNotification?.id ?? ""

  useEffect(() => {
    if (
      notificationLength === 1 &&
      result === notificationType &&
      notificationID === (id ?? notificationID)
    ) {
      action(notifications?.[0]);
    }
  }, [notificationLength, notifications, result, notificationID , action, id, notificationType]);
};

export const useNotificationBanner = (settings) => {
  const [unsaved, setUnsaved] = useState(false);
  let banner = false;

  if (settings === undefined) {
    return [banner, setUnsaved];
  }

  //currently available settings
  const {notifications, bannerActions, timeoutAction, defaultBanner, navigationActions} = settings;
  const hasNotifications = notifications?.length > 0;

  if (defaultBanner) {
    banner = defaultBanner;
  }

  if (unsaved) {
    banner = {
      title: getNotificationItemInfo(`There are unsaved changes!`, faSave),
      state: "grey",
      bannerActions: bannerActions?.filter?.(ba => ba.result === "unsaved"),
      type: "unsaved"
    };
  }

  const getActions = (notification, bannerActions) => {
    let actions = [];
    
    if (notification?.bannerActions) {
      actions.push(...notification.bannerActions);
    }
    if (bannerActions) {
      actions.push(...bannerActions?.filter?.(ba => (ba.result ?? notification.result) === notification.result));
    }

    return actions;
  };

  if (hasNotifications) {
    banner = {
      notifications: notifications.map((n) => ({
        ...n,
        pageActions: getActions(n, bannerActions),
      })),
      navigationActions,
      timeoutAction: timeoutAction,
      timeout: notifications?.[0].timeout,
    };
  }
  
  return [banner, setUnsaved, unsaved];
};


export const getSaveBannerActions = (save, goBack) => {
  return [
    {
      id: 0,
      result: "saved",
      title: "back",
      type: "secondary",
      actionClick: goBack,
    },
    {
      id: 1,
      result: "created",
      title: "back",
      type: "secondary",
      actionClick: goBack,
    },
    {
      id: 2,
      result: "unsaved",
      title: "save",
      type: "secondary black",
      actionClick: save,
    },
    {
      id: 3,
      result: "unsaved",
      title: "discard",
      type: "secondary grey",
      actionClick: goBack,
    },
  ];
};
