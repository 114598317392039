import {getNotificationItemInfo} from "../helpers/Converters";
import {mediumDelay} from "../helpers/JSONapi";
import {faCheck, faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";

export const baseURL = `${process.env.REACT_APP_GOLFSTATUS_BASE_API_URL}/v2`;
export const tournamentsURL = `${baseURL}/tournaments`
export const publicTournamentsURL = `${baseURL}/public-tournaments`
export const tournamentRoundsURL = `${baseURL}/tournament-rounds`
export const registrationOrdersURL = `${baseURL}/registration-orders`
export const tournaemntPackages = `${baseURL}/tournament-packages`
export const tournamentPackageRegistrationFieldSets = `${baseURL}/tournament-package-registration-field-sets`
export const registrationFieldSets = `${baseURL}/registration-field-sets`
export const usersURL = `${baseURL}/users`;
export const tournamentPackageItems = `${baseURL}/tournament-package-items`

export const getCustomerInvoiceDetailsPath = (id) => `${baseURL}/registration-orders/${id}/customer-invoice`;
export const payCustomerInvoicePath = (id) => `${baseURL}/registration-orders/${id}/pay-customer-invoice`;
export const downloadCustomerInvoicePath = (id) => `${baseURL}/registration-orders/${id}/download-customer-invoice`;
export const getUserPaymentOptionsPath = (id) => `${baseURL}/users/${id}/payment-options`;

export const FULFILLED = "fulfilled"
export const PENDING = "pending"
export const REJECTED = "rejected"
/**
 * create a notification for the app
 *
 * @param {String} message message of the notification
 * @param {String} icon icon for notification
 * @param {String} state state of notification [success, grey, error]
 * @param {String} result result of the notification [pending, fulfilled, rejected, unknown]
 * @param {String} timeout timeout for the notification to expire
 * @param {String} actions actions for the notification
 * @param {String} id id for the notification
 * @param {String} error error for the notification
 *
 * @returns {object} notification
 */
export const createNotification = (
  message,
  icon,
  state,
  result,
  timeout,
  actions,
  id,
  error,
  description
) => {
  return {
    message,
    header: getNotificationItemInfo(message ?? "", icon, description),
    state: state ?? "grey",
    result: result ?? "unknown",
    timeout: timeout ?? 0,
    pageActions: actions ?? [],
    id,
    error,
  }
}

const getName = (thunk) => {
  if (thunk?.typePrefix?.includes?.("/")) {
    return thunk?.typePrefix?.split?.("/")?.[1];
  } else {
    return thunk.typePrefix;
  }
};

export const defaultNotificationSettings = {
  pending: {
    icon: faSpinner,
    message: (state, action) => "pending",
    state: "grey",
    result: PENDING,
    timeout: 0,
    actions: [],
    meta: {},
  },
  fulfilled: {
    icon: faCheck,
    message: (state, action) => "fulfilled",
    state: "success",
    result: FULFILLED,
    timeout: mediumDelay,
    actions: [],
    meta: {},
  },
  rejected: {
    icon: faExclamationCircle,
    message: (state, action) => "rejected",
    state: "warning",
    result: REJECTED,
    timeout: 0,
    actions: [],
    meta: {},
  },
};

export const getThunkResponse = (builder,
  thunk,
  callback,) => {
  const notifications = {rejected: defaultNotificationSettings.rejected}
  return thunkResponse(builder, thunk, callback, notifications)
}

export const createThunkResponse = (builder,
  thunk,
  callback,) => {
  const notifications = {...defaultNotificationSettings, fulfilled: {...defaultNotificationSettings.fulfilled, result: "created"}}
  return thunkResponse(builder, thunk, callback, notifications)
}

export const payThunkResponse = (builder, thunk, callback, timeout) => {
  const notifications = {
    ...defaultNotificationSettings,
    pending: {
      ...defaultNotificationSettings.pending,  
      message : () => `Paying invoice`
    },
    fulfilled: {
      ...defaultNotificationSettings.fulfilled,
      message: () => `Invoice has been paid!`
    },
  }
  return thunkResponse(builder, thunk, callback, notifications)
}

export const fileDownloadThunkResponse = (builder, thunk, nameFunction) => {
  const createFile = (state, action, name) => {
    const filename = name
      let blob = new Blob([action.payload.data]);
      if (window.navigator.msSaveBlob) {
        // IE
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        let windowUrl = window.URL || window.webkitURL;
        let fileUrl = windowUrl.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = fileUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(fileUrl);
        }, 0);
      }
  }
  const notifications = {
    ...defaultNotificationSettings,
    pending: {
      ...defaultNotificationSettings.pending,
      message: () => 'Downloading file... this may take a moment.' 
    },
    fulfilled: {
      ...defaultNotificationSettings.fulfilled,
      timeout: 0, 
      message: () => `File has been successfully Downloaded.`
    }
  }

  return thunkResponse(builder, thunk, (state, action) => {createFile(state,action, nameFunction?.(state, action) )}, notifications)
};

export const thunkResponse = (
  builder,
  thunk,
  callback,
  notificationSettings
) => {
  const { pending, fulfilled, rejected } = notificationSettings ?? defaultNotificationSettings;

  const thunkName = getName(thunk);

  return (
    builder.addCase(thunk.pending, (state, action) => {
      state.loading.push(thunkName);
      if (state?.notifications && pending) {
        state.notifications = [
          createNotification(
            pending?.message(state, action),
            pending?.icon,
            pending.state,
            pending?.result,
            pending?.timeout,
            pending?.actions,
            thunkName,
            action.meta,
            pending?.description?.(state, action)
          ),
        ];
      }
    }),
    builder.addCase(thunk.fulfilled, (state, action) => {
      state.loading = state.loading.filter((f) => f !== getName(thunk));
      if (state?.notifications && fulfilled) {
        state.notifications = [
          createNotification(
            fulfilled?.message(state, action),
            fulfilled?.icon,
            fulfilled.state,
            fulfilled?.result,
            fulfilled?.timeout,
            fulfilled?.actions,
            thunkName,
            action.meta,
            pending?.description?.(state, action)
          ),
        ];
      }
      callback?.(state, action);
    }),
    builder.addCase(thunk.rejected, (state, action) => {
      state.loading = state.loading.filter((f) => f !== getName(thunk));
      if (state?.notifications && rejected) {
        state.notifications = [
          createNotification(
            rejected?.message(state, action),
            rejected?.icon,
            rejected.state,
            rejected?.result,
            rejected?.timeout,
            rejected?.actions,
            thunkName,
            action.meta,
            rejected?.description?.(state, action)
          ),
        ];
      }
    })
  );
};





