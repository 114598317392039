import {
  defaultTypography,
  GSButton,
  GSImage,
  GSInfoGroup,
  useMediaQuery,
} from "golfstatus_react_components";
import React from "react";
import { getDate, getDateTimeRange } from "../../helpers/Dates";
import { moneyWithCommas } from "../../helpers/RegexHelper";
import { getRoundsSummary } from "./event-details-item";
import { useTheme } from "../../hooks/themeHooks";

const EventLineItem = (props) => {
  const {
    startAt,
    endAt,
    logo,
    name,
    tournamentFormats,
    tournamentRounds,
    registrationClosed,
    cheapestTeamPackageCost,
    pastEvent,
    publiclyVisible,
    openRegistration,
    style,
    className,
  } = props;

  const {
    numberOfHoles,
    facilityName,
    city,
    state,
    formatName,
  } = getRoundsSummary(tournamentRounds, tournamentFormats);

  const { isPhone, innerWidth } = useMediaQuery();

  const [, theme, getStyle] = useTheme();

  const getCourseAddressString = () => {
    return `${city}, ${state}`;
  };

  const getStartingAtString = () => {
    if (registrationClosed || pastEvent || !publiclyVisible) {
      return "";
    }
    if (openRegistration) {
      return cheapestTeamPackageCost
        ? `Teams starting at ${moneyWithCommas(cheapestTeamPackageCost)}`
        : "";
    }
    return "Private Registration"
  };

  const getDateString = () => {
    if (startAt === endAt) {
      return getDate(startAt);
    } else {
      return getDateTimeRange(startAt, endAt);
    }
  };

  const getButtonStyle = () => {
    if (registrationClosed) {
      return {};
    } else {
      return { ...getStyle(theme.secondaryContainer) };
    }
  };

  const startString = getStartingAtString()

  const dateSection = {
    gap: "medium-large-gap",
    type: innerWidth > 900 ? "align-end" : "",
    sectionItems: [
      {
        type: "primary full-width",
        value: (
          <GSButton
            style={getButtonStyle()}
            title={` ${pastEvent ? "past event" : registrationClosed ? "Registration closed" : "Registration open"}`}
            type={`secondary ${registrationClosed ? pastEvent ? "grey" : "black" : ""} pill`}
          />
        ),
      },
      
    ],
  };

  if(startString !== ""){
    dateSection.sectionItems.push({
      type: "secondary full-width no-wrap",
      value: getStartingAtString(),
    },)
  }
  const logoSection = {
    sectionItems: [
      {
        type: "primary  full-width",
        value: (
          <GSImage
            src={logo}
            size={isPhone() ? "small" : "medium"}
            ratio="wide"
          />
        ),
      },
    ],
  };
  const tournamentInfoSection = {
    sectionItems: [
      {
        type: " full-width",
        style: { ...defaultTypography.mediumBoldBody },
        value: name,
      },
      {
        type: "secondary  full-width",
        value: getDateString(),
      },
    ],
  };
  const tournamentInfoSectionFormat = {
    sectionItems: [
      {
        type: "secondary  full-width",
        value: formatName,
      },
      {
        type: "secondary  full-width",
        value: numberOfHoles,
      },
      {
        type: "secondary  full-width",
        value: facilityName,
      },
      {
        type: "secondary full-width",
        value: getCourseAddressString(),
      },
    ],
  };

  const dateColumn = {
    fit: "centered",
    sections: [
      {
        ...dateSection,
      },
    ],
  };

  const logoColumn = {
    fit: "",
    sections: [
      {
        ...logoSection,
      },
    ],
  };

  const tournamentInfoColumn = {
    fit: "stretch",
    sections: [tournamentInfoSection, tournamentInfoSectionFormat],
  };

  const getGroup = () => {
    let group = [logoColumn, tournamentInfoColumn, dateColumn];
    return group;
  };

  return (
    <event-line-item class={className} style={style}>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </event-line-item>
  );
};

export default EventLineItem;
