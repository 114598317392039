import { faArrowRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  defaultPadding,
  defaultLayouts,
  GSPageSection,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentPaymentSettings,
  getTournamentSponsors,
  selectTournamentPaymentSettings,
  selectTournamentSponsors,
} from "../../../../reducers/tournamentSlice";
import "./event-leaderboards.scss";
import {
  getLeaderboardFlights,
  getPublicLeaderboards,
  selectFavoriteTeams,
  selectLeaderboard,
  selectLeaderboards,
  setFavorites,
  setLeaderboard,
} from "../../../../reducers/leaderboardSlice";
import {
  useHeaderStyle,
  useSectionStyles,
  useTheme,
} from "../../../../hooks/themeHooks";
import { getRouting } from "../../../../helpers/Converters";
import {
  LEADERBOARD_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";
import TournamentDonationWidget from "../../../widgets/tournament-donation-widget";
import PageHeader from "../../../page-header";
import LeaderboardSponsorsWidget from "../../../widgets/leaderboard-sponsors-widget";
import CurrentLeaderboardWidget from "../../../widgets/current-leaderboard-widget";
import LeaderboardFavoritesWidget from "../../../widgets/leaderboard-favorites-widget";
import LeaderboardWidget from "../../../widgets/leaderboard-widget";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import { usePermissions } from "../../../../hooks/permissionHooks";
import { useTournament } from "../../../../hooks/tournamentHooks";

const EventLeaderboards = (props) => {
  const navigate = useNavigate();

  const base = getRouting(eventBaseRoute, [TOURNAMENT_ID, "leaderboards", "*"]);
  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "leaderboards",
    LEADERBOARD_ID,
    "*",
  ]);

  const matchNoID = useMatch(base);
  const matchID = useMatch(route);

  const match = matchID ?? matchNoID;

  const dispatch = useDispatch();
  const leaderboardID = getProp(match?.params, LEADERBOARD_ID) ?? "";

  const currentLeaderboard = useSelector(selectLeaderboard);
  const sponsorships = useSelector(selectTournamentSponsors);

  const tournamentPaymentSettings = useSelector(
    selectTournamentPaymentSettings
  );
  const leaderboards = useSelector(selectLeaderboards);
  const favorites = useSelector(selectFavoriteTeams);
  const tournament = useTournament();

  const tournamentID = tournament?.id;
  const publicUrl = tournament?.publicUrl;

  const techSponsors = sponsorships.filter(
    (sponsor) => sponsor.sponsorshipType === "leaderboard"
  );

  const [, theme, getStyle] = useTheme();

  const { defaultSectionStyle, headerSectionStyle, style } = useSectionStyles();

  const stickyHeaderStye = {
    ...defaultLayouts.stickyTop,
    ...getStyle(theme.surface),
    zIndex: 1,
  };

  const getPageActions = useNavigationButtons("leaderboards");

  const permissions = usePermissions();

  useEffect(() => {
    const faves = JSON.parse(localStorage?.getItem?.("favoriteTeams"));
    dispatch(setFavorites(faves));
  }, [dispatch]);

  useEffect(() => {
    if (tournamentID) {
      dispatch(getPublicLeaderboards(tournamentID));
      dispatch(getTournamentSponsors(tournamentID));
      dispatch(getTournamentPaymentSettings(tournamentID));
      dispatch(getLeaderboardFlights(tournamentID));
    }
  }, [dispatch, tournamentID]);

  useEffect(() => {
    if (currentLeaderboard?.id === undefined) {
      if (leaderboardID === "" && leaderboards.length > 0) {
        dispatch(setLeaderboard(leaderboards?.[0]));
      } else {
        if (currentLeaderboard?.id !== leaderboardID) {
          const found = leaderboards?.find?.((l) => l.id === leaderboardID);
          dispatch(setLeaderboard(found));
        }
      }
    } else if (leaderboardID === "") {
      navigate(currentLeaderboard?.id, { replace: true });
    }
  }, [dispatch, leaderboardID, currentLeaderboard?.id, leaderboards, navigate]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    //
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getFavorites = (leaderboard) => {
    const results =
      leaderboard?.scoringSystem === "match_play" &&
      leaderboard?.type === "round_match"
        ? leaderboard.scores?.filter?.((s) =>
            s.teamKeys?.some?.((k) => favorites.includes?.(k))
          )
        : leaderboard?.scores?.filter?.((s) =>
            favorites?.includes?.(s.teamKey)
          );

    return results;
  };

  const getItemList = () => {
    const faves = getFavorites(currentLeaderboard) ?? [];
    return (
      <div style={{ ...defaultLayouts.fullWidth, ...defaultLayouts.vStack }}>
        <PageHeader header="Leaderboards" />
        {techSponsors?.length > 0 ||
        tournament?.tournamentPromotion?.secondaryLeaderboardPhoto ? (
          <GSPageSection
            style={{ ...stickyHeaderStye, ...defaultPadding.xLargePad }}
            bodyStyle={{ ...defaultPadding.noPad }}
            body={[<LeaderboardSponsorsWidget />]}
          />
        ) : null}

        {currentLeaderboard ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<CurrentLeaderboardWidget />]}
          />
        ) : null}

        {favorites?.length > 0 && faves.length > 0 ? (
          <GSPageSection
            title="My Favorites"
            style={style}
            bodyStyle={{ ...defaultPadding.noPad, overflow: "visible" }}
            body={[<LeaderboardFavoritesWidget />]}
          />
        ) : null}

        <GSPageSection
          style={style}
          bodyStyle={{ ...defaultPadding.noPad, overflow: "visible" }}
          body={[<LeaderboardWidget />]}
        />

        {permissions.donationsActive() ? (
          <GSPageSection
            sectionActions={[
              {
                title: "Donate Now",
                type: "black mobile-icon",
                rightIcon: faArrowRight,
                onClick: () => {
                  navigate(
                    getRouting(eventBaseRoute, [publicUrl, "donate"])
                  );
                },
              },
            ]}
            {...defaultSectionStyle}
            title="Make a Donation"
            description={tournamentPaymentSettings?.donationMessage}
            body={[<TournamentDonationWidget />]}
          />
        ) : null}
      </div>
    );
  };

  const { headerStyle } = useHeaderStyle();

  return (
    <event-leaderboards>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
      ></GSListPage>
    </event-leaderboards>
  );
};

export default EventLeaderboards;
