import React, { useEffect } from "react";
import "./credit-card-details.scss";
import {
  GSForm,
  GSSidePanelPage,
  useFormDataValidation,
} from "golfstatus_react_components";
import {
  faBank,
  faChevronLeft,
  faCreditCard,
  faExclamationCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  addNotification,
  clearNotifications,
  selectBillingAddress,
  selectInvoiceLoading,
  selectInvoiceNotifications,
  selectPaymentDetails,
  selectSelectedPaymentOption,
  setBillingAddress,
  setPaymentDetails,
  setSelectedPaymentOption,
} from "../../../../reducers/invoiceSlice";

import { getCurrentUser } from "../../../../app/gs-session";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import { getUserPaymentOptions } from "../../../../reducers/userSlice";
import {
  internationalCardCheck,
  selectRegistrationCustomer,
  selectRegistrationOrder,
  updateCustomer,
  updateOrder,
} from "../../../../reducers/tournamentSlice";
import {
  getBankFormSections,
  getCreditCardFormSections,
} from "../../../../forms/CheckoutForm";

import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import { selectTheme } from "../../../../reducers/appSlice";
import { useTheme } from "../../../../hooks/themeHooks";
import {
  usePaymentOptions,
  useTournament,
} from "../../../../hooks/tournamentHooks";
import { usePermissions } from "../../../../hooks/permissionHooks";
import { createNotification, REJECTED } from "../../../../app/api";
import { longDelay } from "../../../../helpers/JSONapi";
import { BANK_ACCOUNT, CREDIT_CARD } from "../../../widgets/payment-select-widget";

export const newCreditCard = {
  icon: faPlus,
  title: "Add New Credit Card",
  fee: 0.05,
  feeLabel: "5% Fee",
  paymentType: "credit_card",
  saved: false,
};

const CreditCardDetails = (props) => {
  const { paymentType } = props ?? {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const mode = useSelector(selectTheme);
  const [, theme, getStyle] = useTheme();

  const tournament = useTournament();

  const billingAddress = useSelector(selectBillingAddress);
  const notifications = useSelector(selectInvoiceNotifications);
  const loading = useSelector(selectInvoiceLoading);
  const paymentDetails = useSelector(selectPaymentDetails);
  const { availablePayments, availableBanks, availableCreditCards} = usePaymentOptions();
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);

  const registrationOrder = useSelector(selectRegistrationOrder);
  const customer = useSelector(selectRegistrationCustomer);

  const permissions = usePermissions();

  // form context
  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    setData: (update) => {
      dispatch(setPaymentDetails(update));
    },
    data: paymentDetails,
  });

  context.billingAddress = billingAddress;
  context.includeFees = tournament?.organizationPaysPlatformFee;
  context.paymentDetails = paymentDetails;
  context.selectedPaymentOption = selectedPaymentOption;
  context.loading = loading;
  context.tournament = tournament;
  context.isValid = isValid;
  context.isEscrow = permissions.escrowActive();
  context.mode = mode;

  context.stripePaymentUpdated = () => {
    if (!isValid) {
      setIsValid(true);
    }
  };

  context.itemStyle = getStyle(theme.surface);

  context.onBillingAddressChange = (value, field) => {
    setIsValid(true);
    let updated = { ...billingAddress };
    updated[field] = value;
    dispatch(setBillingAddress(updated));
  };

  context.onSelectedPaymentOptionChange = (option) => {
    setIsValid(true);
    dispatch(setSelectedPaymentOption(option));
  };

  context.isUserLoggedIn = () => {
    return !!getCurrentUser();
  };

  context.getPaymentOptions = () => {
    const allOptions = [];
    if (availablePayments && availablePayments.length > 0) {
      if (paymentType === CREDIT_CARD) {
        allOptions.push(
          ...availableCreditCards
            .map?.((o) => {
              return { ...o, icon: faCreditCard };
            })
        );
      }
      if (paymentType === BANK_ACCOUNT) {
        allOptions.push(
          ...availableBanks
            ?.map?.((o) => {
              return { ...o, icon: faBank };
            })
        );
      }
    }
    if (paymentType === CREDIT_CARD) {
      allOptions.push(newCreditCard);
    }
    return allOptions;
  };

  const payWithGlobal = async (paymentToken) => {
    if (!paymentToken) {
      return;
    }
    const update = {
      ...registrationOrder,
      paymentOptionKey: null,
      globalToken: paymentToken,
      cardFirstName: paymentDetails.firstName,
      cardLastName: paymentDetails.lastName,
    };
    const customerUpdate = {
      ...customer,
      address: billingAddress?.address,
      address2: billingAddress?.address2,
      city: billingAddress?.city,
      state: billingAddress?.state,
      postal: billingAddress?.postal,
    };
    dispatch(updateCustomer(customerUpdate));
    dispatch(updateOrder(update));
    leftNavigation();
  };

  const payWithStripe = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded
      return;
    }
    const card = elements.getElement(CardNumberElement);
    if (card === null) {
      const update = {
        ...registrationOrder,
        paymentOptionKey: null,
        globalToken: null,
        stripeToken: null,
        cardName: null,
        last4: null,
        brand: null,
      };
      dispatch(updateOrder(update));
      leftNavigation();
      return;
    }
    const result = await stripe.createToken(card);
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      const notification = createNotification(
        "Could not validate the card you are using at this time. Please check your card details and try again",
        faExclamationCircle,
        "warning",
        REJECTED,
        longDelay
      );
      dispatch(addNotification(notification));
      return;
    } else {
      const update = {
        ...registrationOrder,
        paymentOptionKey: null,
        globalToken: null,
        stripeToken: result.token?.id,
        cardName: result.token?.card?.name,
        last4: result.token?.card?.last4,
        brand: result.token?.card?.brand,
      };
      const customerUpdate = {
        ...customer,
        address: billingAddress?.address,
        address2: billingAddress?.address2,
        city: billingAddress?.city,
        state: billingAddress?.state,
        postal: billingAddress?.postal,
      };
      dispatch(updateCustomer(customerUpdate));

      dispatch(
        internationalCardCheck({
          registrationOrder: update,
          cardInfo: {
            cardCountryCode: result.token?.card?.country,
            organizationKey: tournament?.organization?.id,
          },
        })
      );

      leftNavigation();
    }
  };

  const payWithCard = (paymentToken) => {
    if (isSavedPayment()) {
      const update = { ...registrationOrder };
      update.paymentOptionKey = selectedPaymentOption?.id;
      update.globalToken = null;
      update.stripeToken = null;
      update.status = "ready";
      dispatch(updateOrder(update));
      leftNavigation();
    } else {
      if (permissions.escrowActive()) {
        payWithGlobal(paymentToken);
      } else {
        payWithStripe();
      }
    }
  }

  const payWithBank = () => {
    if (isSavedPayment()) {
      const update = { ...registrationOrder };
      update.paymentOptionKey = selectedPaymentOption?.id;
      update.globalToken = null;
      update.stripeToken = null;
      update.status = "ready";
      dispatch(updateOrder(update));
      leftNavigation();
    } 
  }

  const clearPayment = () => {
    if (
      selectedPaymentOption === null
    ) {
      const update = {
        ...registrationOrder,
        globalToken: null,
        cardFirstName: null,
        cardLastName: null,
        paymentOptionKey: null,
      };
      dispatch(updateOrder(update));
      leftNavigation();
    }
  }

  context.submitPayment = async (paymentToken = null) => {
    if (!isValid) {
      return;
    }

    if(paymentType === CREDIT_CARD){
      if(selectedPaymentOption === null && availableCreditCards.length > 0){
        clearPayment()
      }
      else{
        payWithCard(paymentToken)
      }
    }
    else if(paymentType === BANK_ACCOUNT){
      if(selectedPaymentOption === null && availableBanks?.length > 0){
        clearPayment()
      }
      payWithBank()
    }
  };

  // effects

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      dispatch(getUserPaymentOptions(user.uid));
    } else {
      // TODO: call to action to invite the user to log in if they have saved payment methods
    }
  }, [dispatch]);

  const isSavedPayment = () => {
    return selectedPaymentOption?.saved ?? false;
  };

  const leftNavigation = () => {
    dispatch(clearNotifications());
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title:
        paymentType === "credit_card" ? `Credit/Debit Cards` : "Bank Accounts",
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  // TODO: update these notification settings and banner to include validations
  let notificationSettings = {
    notifications: notifications,
    saveAction: context.submitPayment,
    bannerActions: getSaveBannerActions(context.submitPayment, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  const getContent = () => {
    if (paymentType === "credit_card") {
      return (
        <GSForm
          formTitle={"Credit/Debit Cards"}
          formSections={getCreditCardFormSections(context)}
        ></GSForm>
      );
    } else {
      return (
        <GSForm
          formTitle={"Bank Accounts"}
          formSections={getBankFormSections(context)}
        ></GSForm>
      );
    }
  };

  const getDrawerActions = () => {
    return [
      {
        name: "Save & Continue",
        isDisabled: !isValid,
        action: () => context.submitPayment(),
        type: "black",
      },
      {
        name: "Cancel",
        action: leftNavigation,
        type: "grey",
      },
    ];
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    //name the component tag
    <credit-card-details>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </credit-card-details>
  );
};

//export the new namet
export default CreditCardDetails;
