import { faArrowRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSEmptyList,
  defaultPadding,
  GSPageSection,
  defaultLayouts,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  getTournamentPaymentSettings,
  selectRegistrationOrder,
} from "../../../../reducers/tournamentSlice";

import "./event-donation.scss";
import { getRouting } from "../../../../helpers/Converters";
import {
  eventBaseRoute,
} from "../../../../routes/event";
import FullDonationWidget from "../../../widgets/full-donation-widget";
import { useHeaderStyle, useSectionStyles } from "../../../../hooks/themeHooks";
import CartStepItem from "../cart/cart-step-item";

import PageHeader from "../../../page-header";
import PageFooter from "../cart/page-footer";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import { getOrderItemCount, getPriceFormatted, getRegistrationSummary } from "../../../../models/Registration";

import { useTournament } from "../../../../hooks/tournamentHooks";

const EventDonation = (props) => {
  const tournament = useTournament()

  const navigate = useNavigate();

  const tournamentID = tournament?.id;

  const registrationOrder = useSelector(selectRegistrationOrder);

  const dispatch = useDispatch();

  const {headerSectionStyle} = useSectionStyles()

  const getPageActions = useNavigationButtons("donate")


  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentPaymentSettings(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Donations"
        detail="This event is currently not accepting donations..."
        actions={[
          {
            title: "Register",
            type: "black",
            onClick: () => {},
          },
        ]}
        style={defaultPadding.emptyListPad}
      />
    );
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    return false;
    //
    //return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };


  const itemCount = (registrationOrder?.donationOrderItems?.length ?? 0) + getOrderItemCount(registrationOrder);
  const price = getPriceFormatted(registrationOrder);

  const donate = (amount) => {
    dispatch(calculatePricing(registrationOrder));
    navigate(
      getRouting(eventBaseRoute, [
        tournamentID,
        "cart",
        registrationOrder?.id,
      ])
    );

  };

  const getItemList = () => {
    if (tournament.isDonationAction) {
      return getEmptyListMessage();
    }
    return (
      <div style={{ ...defaultLayouts.fullWidth, ...defaultLayouts.vStack }}>
        <PageHeader header="Donate"/>
        <GSPageSection
          {...headerSectionStyle}
          body={[
            <FullDonationWidget style={{ ...defaultLayouts.fullWidth }} />,
          ]}
        />
        <PageFooter showFooter={itemCount > 0}>
        <CartStepItem
            description={getRegistrationSummary(registrationOrder, price)}
            nextStep="Next Step: Add Contact Details"
            style={{
              ...defaultLayouts.vStack,
              ...defaultLayouts.fullWidth,
              maxWidth: "1080px",
            }}
            actions={[
              {
                title: "Continue",
                rightIcon: faArrowRight,
                type: "black",
                onClick: () => {
                  donate()
                },
              },
            ]}
          />
        </PageFooter>
      </div>
    );
  };

  const {headerStyle} = useHeaderStyle()

  return (
    <event-donation>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle = {headerStyle}
      ></GSListPage>
    </event-donation>
  );
};

export default EventDonation;
