import {
  defaultBorders,
  defaultGapping,
  defaultLayouts,
  defaultPadding,
  defaultTypography,
  golfstatusTypography,
  GSItemList,
  GSSplitView,
  useMediaQuery,
} from "golfstatus_react_components";
import React from "react";
import { sortPackages } from "../event-packages";
import {
  faFlag,
  faTicket,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { getRouting } from "../../../../helpers/Converters";
import { eventBaseRoute } from "../../../../routes/event";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../../../../hooks/themeHooks";
import { useTournament, useTournamentPackages } from "../../../../hooks/tournamentHooks";

export const getAvailablePackages = (tournamentPackages) => {
  const {
    teamPackages,
    playerPackages,
    sponsorPackages,
    addonPackages,
  } = sortPackages(tournamentPackages);

  let available = [];

  if (sponsorPackages?.length > 0) {
    available.push({
      label: "Sponsorships",
      icon: faFlag,
      id: "sponsor",
    });
  }
  if (teamPackages?.length > 0) {
    available.push({
      label: "Team Registrations",
      icon: faUsers,
      id: "team",
    });
  }
  if (playerPackages?.length > 0) {
    available.push({
      label: "Player Registrations",
      icon: faUser,
      id: "player",
    });
  }
  if (addonPackages?.length > 0) {
    available.push({
      label: "Add-Ons & Extras",
      icon: faTicket,
      id: "extras",
    });
  }
  return available;
};

const PackageFilter = (props) => {
  const { filter } = props;

  const {accessiblePackages} = useTournamentPackages();
  const tournament = useTournament();

  const navigate = useNavigate()

  const [,theme, getStyle] = useTheme()

  const availablePackages = getAvailablePackages(accessiblePackages)

  const addFilter = (id) => {
    let filters = [...filter];
    if (!filter.includes(id)) {
      filters.push(id);
      let filterParam = filters.join?.("&view=");
      navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, "packages", `?view=${filterParam}`]));
    }
  };

  const removeFilter = (id) => {
    let filters = filter.filter?.((f) => f !== id);
    let filterParam = filters.join?.("&view=");
    navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, "packages", `?view=${filterParam}`]));
  };

  const getFilterStyle = (id) => {
      let defaultStyle = {
        ...defaultPadding.xLargePad,
        ...defaultTypography.body,
        ...defaultBorders.mediumLargeBorderRadius,
        ...defaultLayouts.fill,
        ...getStyle(theme.surface),
        borderWidth: "4px",
        borderStyle: "solid",
        ...defaultLayouts.vStack.align("center", "center"),
        ...defaultGapping.mediumLargeGap,
        textAlign: "center",
      };
  
      if (filter.includes?.(id)) {
        return {
          ...defaultStyle,
        };
      }
      return {
        ...defaultStyle,
        ...getStyle(theme.outlineVariant),
      };
    };

  const getFilterItem = (filter) => {
    return (
      <div className="hover-outline" style={getFilterStyle(filter.id)}>
        <FontAwesomeIcon
          style={{
            fontSize: golfstatusTypography.mediumFont,
            lineHeight: golfstatusTypography.squareLineHeight,
          }}
          icon={filter.icon}
        />
        {filter.label}
      </div>
    );
  };
  
  const { isPhone } = useMediaQuery();

  return (
    <package-filter>
      <GSSplitView
        style={{ ...defaultLayouts.fullWidth }}
        dividerStyle={{ width: "0" }}
        left={
          <div
            style={{
              ...defaultLayouts.vStack.align("flex-start", "center"),
              ...defaultGapping.mediumLargeGap,
              height: "100%",
            }}
          >
            <h2 style={{ ...defaultTypography.headline2 }}>
              Let's Get Started
            </h2>
            <div style={{ opacity: ".7" }}>
              Select the package types you’re looking for.
            </div>
          </div>
        }
        right={
          <GSItemList
            type="inline-grid medium-large-gap selectable"
            style={{ ...defaultLayouts.fullWidth }}
            gridColumns={
              isPhone() ? 1 : Math.min(2, availablePackages?.length)
            }
            itemSelected={(t) => {
              if (filter.includes?.(t.id)) {
                removeFilter(t.id);
              } else {
                addFilter(t.id);
              }
            }}
            items={availablePackages}
            listItem={(filter) => getFilterItem(filter)}
          />
        }
      />
    </package-filter>
  );
};

export default PackageFilter
