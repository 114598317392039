import { GSImage, GSInfoGroup, GSItemList } from "golfstatus_react_components";
import React from "react";
import { getDate, getDateTimeRange } from "../../helpers/Dates";
import './event-details-item.scss';

export const getRoundsSummary = (tournamentRounds, tournamentFormats) => {

  const firstRound = tournamentRounds?.[0] ?? {}
  let format = {...tournamentFormats?.[0]}
  let numberOfHoles = firstRound?.numberOfHoles
  let facilityName = firstRound?.facility?.name
  let city = firstRound?.facility?.city
  let state = firstRound?.facility?.state

  let formatName = format?.name ?? ""

  tournamentFormats?.forEach?.(tf => {
    if(tf.name !== formatName){
      formatName = "Multiple Formats" 
    }
  })

  tournamentRounds?.forEach?.(tr => {
    if(tr?.numberOfHoles !== numberOfHoles){
      numberOfHoles = "Holes Vary"
    }
    if(tr?.facility?.name !== facilityName){
      facilityName = "Multiple Facilities"
    }
    if(tr?.facility?.city !== city || tr?.facility?.state !== state){
      city = "Multiple Locations"
    }
  })

  if(numberOfHoles !== "Holes Vary"){
    numberOfHoles = `${numberOfHoles} Holes`
  }

  return {firstRound, format, numberOfHoles, facilityName, city, state, formatName}
}

const EventDetailsItem = (props) => {

  const {name, startAt, endAt,  logo, sponsorLogo, addLogos, tournamentRounds, style} = props

  const {numberOfHoles, facilityName, city, state, formatName} = getRoundsSummary(tournamentRounds, tournamentRounds?.flatMap?.(tr => tr.tournamentFormats))

  let images = [
    logo
  ]

  if((sponsorLogo ?? "") !== ""){
    images.push(sponsorLogo)
  }

  const getDateString = () => {
      if (startAt === endAt) {
        return getDate(startAt);
      } else {
        return getDateTimeRange(startAt, endAt);
      }
    };

  const tournamentNameSection = {
    sectionItems: [
      {
        type: "headline-1 full-width",
        value: name,
      },
    ]
  }

  const tournamentInfoSection = {

    sectionItems: [
      {
        type: "secondary full-width",
        value: getDateString(),
      },
    ]
  }

  const roundInfoSection = {
    sectionItems: [
      {
        type: "secondary full-width",
        value: `${formatName}`,
      },
      {
        type: "secondary full-width",
        value: numberOfHoles ,
      },
      {
        type: "secondary full-width",
        value: facilityName,
      },
      {
        type: "secondary full-width",
        value: city === "Multiple Locations" ? city : `${city}, ${state}`,
      },
    ]
  }

  const imageSection = {
    sectionItems: [
      {
        type: "primary full-width",
        value: <GSItemList type="horizontal medium-gap mobile-vertical" items={images} listItem={(image) => <GSImage src={image} />} />,
      },
    ]
  }


  const tournamentInformationColumn ={
    fit: "stretch",
    sections: [
      tournamentNameSection
    ],
  }

  if(startAt){
    tournamentInformationColumn?.sections?.push?.(tournamentInfoSection)
  }

  if(tournamentRounds?.length > 0){
    tournamentInformationColumn?.sections?.push?.(roundInfoSection)
  }

  const extrasColumn = {
    type:"align-end mobile-align-start",
    sections:[
      imageSection
    ]
  }

  const getGroup = () => {
    let group = [tournamentInformationColumn]
    if(addLogos){
      group.push(extrasColumn)
    }
    return group
  }

  return <event-details-item style={style}>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </event-details-item>
}


export default EventDetailsItem