import { GSItemList, defaultLayouts, defaultPadding, defaultBorders, useMediaQuery } from "golfstatus_react_components";
import React from "react";
import PackageGroupLineItem from "../events/event/packages/package-group-line-item";
import { useTheme } from "../../hooks/themeHooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectPublicTournament } from "../../reducers/tournamentSlice";

const AdditionalPagesWidget = () => {
  const items = [];

  const [,theme,getStyle] = useTheme()

  const { isTablet, isPhone } = useMediaQuery();

  const navigate = useNavigate()

  const tournament = useSelector(selectPublicTournament)

  if (tournament?.tournamentRounds?.length > 0) {
    items.push({
      title: "Rounds",
      description: "View All",
      route: "rounds",
    });
  }

  if (!tournament?.tournamentPromotion?.hideRegistrants) {
    items.push({
      title: "Registrants",
      description: "View All",
      route: "registrants",
    });
  }

  if (!tournament?.tournamentPromotion?.hideLeaderboards) {
    items.push({
      title: "Leaderboards",
      description: "View All",
      route: "leaderboards",
    });
  }


  return (
    <GSItemList
      type="inline-grid medium-large-gap selectable"
      style={{ ...defaultLayouts.fullWidth }}
      gridColumns={isTablet() ? Math.min(2, items.length) : isPhone() ? 1 : items?.length ?? 3}
      items={items}
      itemSelected={(item) => {navigate(item.route)}}
      listItem={(item) => (
        <PackageGroupLineItem
        className="hover-outline"
          style={{
            ...defaultLayouts.fill,
            ...defaultPadding.xLargePad,
            ...defaultBorders.mediumLargeBorderRadius,
            ...getStyle(theme.surface),
          }}
          navigate={() => {
            navigate(item.route);
          }}
          sponsorshipType={item.title}
          icon={item.icon}
          description={item.description}
        />
      )}
    />
  );
}

export default AdditionalPagesWidget