import React from 'react';
import './registration-order-summary.scss';
import {GSItemList, GSToggle} from "golfstatus_react_components";
import {moneyWithCommas} from "../helpers/RegexHelper";
import RegistrationOrderSummaryLineItem from "./registration-order-summary-line-item";

const RegistrationOrderSummary = ({details, paymentDetails, selectedPaymentOption, registrantCanOptToCoverFees, isRegistrantCoveringFees, toggleRegistrantCoversFees, calculateFees}) => {

  const compareCosts = (a, b) => {
    return b.cost - a.cost;
  };

  const isRegistrationOrderPaid = () => {
    return details?.registrationOrder?.status === "paid";
  };

  const getFeesPaid = () => {
    return details?.registrationOrder?.golfstatusFees + details?.registrationOrder?.stripeFees;
  };

  const registrantCoversFees = () => {
    if (isRegistrationOrderPaid()) {
      return details?.registrationOrder?.registrantCoversFees;
    } else {
      // TODO: this is clearly a function, but throws an error if you try to call it like a function...
      return isRegistrantCoveringFees;
    }
  };

  const getOrderSummaryItems = () => {
    let registrationOrderItems = [];

    details?.registrationOrderItems?.forEach((roi) => {
      const tournamentPackage = details?.packages?.find((p) => p.id === roi.tournamentPackageId);
      if (tournamentPackage) {
        registrationOrderItems.push({
          label: `${tournamentPackage.name} (${roi.quantity}x)`,
          value: moneyWithCommas(tournamentPackage.cost),
          cost: tournamentPackage.cost,
        });
      }
    });
    // sort items from largest to smallest before adding fees
    registrationOrderItems.sort(compareCosts);

    if (registrantCoversFees()) {
      if (isRegistrationOrderPaid()) {
        registrationOrderItems.push({
          label: "Fees",
          value: moneyWithCommas(getFeesPaid()),
          cost: getFeesPaid(),
        });
      } else {
        if (registrantCanOptToCoverFees()) {
          registrationOrderItems.push({
            label: `Cover ${details?.organization?.name}'s Fees`,
            value: (
                <GSToggle
                  value={paymentDetails?.registrantCoversFees}
                  trueDescription="Yes"
                  falseDescription="No"
                  onClick={() => toggleRegistrantCoversFees()}
                  rowReverse={true}
                />
            ),
            customView: true,
            isEditable: true,
          });
        }
        if (isRegistrantCoveringFees()) {
          const totalFees = calculateFees();
          registrationOrderItems.push({
            label: "Fees",
            value: selectedPaymentOption ? moneyWithCommas(totalFees) : "Select a Payment Method to Calculate Fees",
            cost: totalFees,
          });
        }
      }
    }

    return registrationOrderItems;
  };

  return (
    <registration-order-summary>
      <div className="title">Order Summary</div>
      <GSItemList
        items={getOrderSummaryItems()}
        type="vertical"
        listItem={(item) => <RegistrationOrderSummaryLineItem item={item} />}
      ></GSItemList>
    </registration-order-summary>
  );
};

export default RegistrationOrderSummary;
