import React from "react";
import './customer-invoice-total-banner.scss';
import {moneyWithCommas} from "../../helpers/RegexHelper";
import {GSButton, GSInfoGroup} from "golfstatus_react_components";
import moment from "moment";
import { useTheme } from "../../hooks/themeHooks";

const CustomerInvoiceTotalBanner = ({details, payInvoice}) => {


  const [, theme, getStyle] = useTheme()

  const paid = getStyle(theme.tertiaryContainer)
  const due = getStyle(theme.secondaryContainer)

  const isInvoicePaid = () => {
    return details?.registrationOrder?.status === "paid";
  };

  const isInvoiceVoid = () => {
    return details?.registrationOrder?.status === "void";
  };

  const isInvoicePayable = () => {
    return !isInvoicePaid() && !isInvoiceVoid();
  };

  const getInvoiceTotalCost = () => {
    if (isInvoicePaid()) {
      return details?.registrationOrder?.purchaseCost;
    } else {
      return details?.registrationOrderItems?.reduce((acc, roi) => {
        const tournamentPackage = details?.packages?.find((p) => p.id === roi.tournamentPackageId);
        return tournamentPackage ? acc + (tournamentPackage.cost*roi.quantity) : acc;
      }, 0);
    }
  };

  const getPaymentDateLabel = () => {
    if (isInvoicePaid()) {
      return `Paid on ${moment(details?.registrationOrder?.invoicePaidAt).format("MMM DD, YYYY")}`;
    } else if (isInvoiceVoid()) {
      return `Voided on ${moment(details?.registrationOrder?.updatedAt).format("MMM DD, YYYY")}`;
    } else {
      return `Due on ${moment(details?.invoice?.dueDate).format("MMM DD, YYYY")}`;
    }
  };

  let groups = [
    {
      sections: [
        {
          sectionItems: [
            {
              type: "primary no-wrap",
              value: "Invoice Total",
            },
            {
              type: "secondary no-wrap",
              value: getPaymentDateLabel(),
            },
          ],
        },
      ],
    },
    {
      sections: [
        {
          sectionItems: [
            {
              type: "primary no-wrap total",
              value: moneyWithCommas(getInvoiceTotalCost()),
            },
          ],
        },
      ],
    },
  ];

  // Add this conditionally so we aren't introducing extra space with gaps
  if (payInvoice && isInvoicePayable()) {
    groups[0].sections.push({
      sectionItems: [
        {
          type: "primary no-wrap",
          value: <GSButton title={"Pay Invoice"} type={"black"} onClick={payInvoice} />,
        },
      ],
    });
  }

  return (
    <customer-invoice-total-banner>
      <div style={isInvoicePaid() ? paid : due} className={`total-container ${isInvoicePaid() ? 'paid' : 'due'}`}>
        <GSInfoGroup dataGroups={groups} listType={"total-info"} />
      </div>
    </customer-invoice-total-banner>
  );
}

export default CustomerInvoiceTotalBanner;
