import { Route } from "react-router-dom";
import EventDetails from "../components/events/event/event-details";
import EventSponsorships from "../components/events/event/sponsors/event-sponsorships";
import PackageDetails from "../components/events/event/packages/package-details";
import EventRegistrants from "../components/events/event/event-registrants";
import EventLeaderboards from "../components/events/event/leaderboards/event-leaderboards";
import LeaderboardSelect from "../components/events/event/leaderboards/leaderboard-select";
import EventDonation from "../components/events/event/donate/event-donation";
import EventCart from "../components/events/event/cart/event-cart";
import GuestCheckoutPage from "../components/events/event/cart/guest-checkout-page";
import OrderItemForm from "../components/events/event/cart/order-item-form";
import EventCartCheckout from "../components/events/event/cart/event-cart-checkout";
import CreditCardDetails from "../components/events/event/cart/credit-card-details";
import EventCartCheckoutConfirmation from "../components/events/event/cart/event-cart-checkout-confirmation";

import EventPackages from "../components/events/event/event-packages";
import { getRouting } from "../helpers/Converters";
import TournamentLeaderboardResult from "../components/events/event/leaderboards/tournament-leaderboard-result";
import EventRounds from "../components/events/event/rounds/event-rounds";
import EventRoundSelect from "../components/events/event/rounds/event-round-select";
import RoundHoleAssignments from "../components/events/event/rounds/round-hole-assignments";
import RoundRules from "../components/events/event/rounds/round-rules";
import SponsorImageEditor from "../components/events/event/cart/sponsor-image-editor";
import DonationItemForm from "../components/events/event/cart/donation-item-form";
import PackageAccessForm from "../components/events/event/packages/package-access-form";
import GolfstatusRedirect from "../components/golfstatus-redirect";

export const SLUG = ":slug";
export const TOURNAMENT_ID = ":id";
export const PACKAGE_ID = ":packageID";
export const LEADERBOARD_ID = ":leaderboardID";
export const TEAM_KEY = ":teamKey";
export const ORDER_ID = ":orderID";
export const ORDER_ITEM_ID = ":orderItemID";
export const ROUND_ID = ":roundID";
export const SPONSOR_ID = ":sponsorID";

export const eventBaseRoute = `/event`;

export const getProp = (params, param) => {
  const prop = param.replace(":", "");
  return params?.[prop];
};

export const getEventRoutes = () => {
  const getRoute = (nested = []) => {
    return getRouting(eventBaseRoute, [TOURNAMENT_ID, ...nested]);
  };

  return (
    <>
      <Route path={getRoute()} element={<EventDetails />} />

      <Route path={getRoute(["sponsorships"])} element={<EventSponsorships />}>
        <Route
          path={getRoute(["sponsorships", "package", PACKAGE_ID])}
          element={<PackageDetails parent="sponsorships" />}
        />
      </Route>



      <Route path={getRoute(["rounds"])} element={<EventRounds />}></Route>

      <Route path={getRoute(["rounds", ROUND_ID])} element={<EventRounds />}>
        <Route
          path={getRoute(["rounds", ROUND_ID, "select"])}
          element={<EventRoundSelect />}
        ></Route>
        <Route
          path={getRoute(["rounds", ROUND_ID, "hole-assignments"])}
          element={<RoundHoleAssignments />}
        ></Route>
        <Route
          path={getRoute(["rounds", ROUND_ID, "rules"])}
          element={<RoundRules />}
        ></Route>
      </Route>

      <Route path={getRoute(["registrants"])} element={<EventRegistrants />}>
        <Route
          path={getRoute(["registrants", "package", PACKAGE_ID])}
          element={<PackageDetails parent="registrants" />}
        />
      </Route>

      <Route
        path={getRoute(["leaderboards"])}
        element={<EventLeaderboards />}
      />

      <Route
        path={getRoute(["leaderboards", LEADERBOARD_ID])}
        element={<EventLeaderboards />}
      >
        <Route
          path={getRoute(["leaderboards", LEADERBOARD_ID, "select"])}
          element={<LeaderboardSelect />}
        />
        <Route
          path={getRoute(["leaderboards", LEADERBOARD_ID, "results", TEAM_KEY])}
          element={<TournamentLeaderboardResult />}
        />
      </Route>

      <Route path={getRoute(["packages"])} element={<EventPackages />}>
        <Route
          path={getRoute(["packages", PACKAGE_ID])}
          element={<PackageDetails />}
        />
        <Route
          path={getRoute(["packages", "access"])}
          element={<PackageAccessForm />}
        />
      </Route>

      <Route path={getRoute(["donate"])} element={<EventDonation />} />

      <Route path={getRoute(["cart", ORDER_ID])} element={<EventCart />}>
        <Route
          path={getRoute(["cart", ORDER_ID, "customer"])}
          element={<GuestCheckoutPage />}
        />

        <Route
          path={getRoute(["cart", ORDER_ID, ORDER_ITEM_ID, "team"])}
          element={<OrderItemForm formType="team" />}
        />

        <Route
          path={getRoute(["cart", ORDER_ID, "donation", "message"])}
          element={<DonationItemForm/>}
        />

        <Route
          path={getRoute(["cart", ORDER_ID, ORDER_ITEM_ID, "sponsor"])}
          element={<OrderItemForm formType="sponsor" />}
        />

        <Route
          path={getRoute([
            "cart",
            ORDER_ID,
            ORDER_ITEM_ID,
            "sponsor",
            SPONSOR_ID,
            "Image",
          ])}
          element={<SponsorImageEditor />}
        />

        <Route
          path={getRoute(["cart", ORDER_ID, ORDER_ITEM_ID, "player"])}
          element={<OrderItemForm formType="player" />}
        />

        <Route
          path={getRoute(["cart", ORDER_ID, "orderForm"])}
          element={<OrderItemForm formType="order" />}
        />
      </Route>

      <Route
        path={getRoute(["cart", ORDER_ID, "checkout"])}
        element={<EventCartCheckout />}
      >
        <Route
          path={getRoute(["cart", ORDER_ID, "checkout", "customer"])}
          element={<GuestCheckoutPage />}
        />
        <Route
          path={getRoute(["cart", ORDER_ID, "checkout", "card"])}
          element={<CreditCardDetails paymentType="credit_card" />}
        />
        <Route
          path={getRoute(["cart", ORDER_ID, "checkout", "bank"])}
          element={<CreditCardDetails paymentType="bank_account" />}
        />
      </Route>

      <Route
        path={getRoute(["cart", ORDER_ID, "checkout", "receipt"])}
        element={<EventCartCheckoutConfirmation />}
      />



      {/* redirects */}
      <Route path={getRoute(["details","registration-details"])} element={<GolfstatusRedirect from={getRoute(["details","registration-details"])} to={getRoute()} />}/>
      <Route path={getRoute(["sponsor","sponsor-list"])} element={<GolfstatusRedirect from={getRoute(["sponsor","sponsor-list"])} to={getRoute(["sponsorships"])} />}/>
      <Route path={getRoute(["sponsor","available-sponsorships"])} element={<GolfstatusRedirect from={getRoute(["sponsor","available-sponsorships"])} to={getRoute(["sponsorships"])} />}/>
      <Route path={getRoute(["register"])} element={<GolfstatusRedirect from={getRoute(["register"])} to={getRoute(["packages"])} />}/>
      <Route path={getRoute(["course"])} element={<GolfstatusRedirect from={getRoute(["course"])} to={getRoute(["rounds"])} />}/>
      <Route path={getRoute(["hole-assignments"])} element={<GolfstatusRedirect from={getRoute(["hole-assignments"])} to={getRoute(["rounds"])} />}/>
      <Route path={getRoute(["leaderboard-results"])} element={<GolfstatusRedirect from={getRoute(["leaderboard-results"])} to={getRoute(["leaderboards"])} />}/>
      <Route path={getRoute(["flight-results"])} element={<GolfstatusRedirect from={getRoute(["flight-results"])} to={getRoute(["leaderboards"])} />}/>
      <Route path={getRoute(["mobile-register"])} element={<GolfstatusRedirect from={getRoute(["mobile-register"])} to={getRoute(["packages"])} />}/>
      <Route path={getRoute(["details"])} element={<GolfstatusRedirect from={getRoute(["details"])} to={getRoute()} />}/>
      <Route path={getRoute(["details","round-details"])} element={<GolfstatusRedirect from={getRoute(["details","round-details"])} to={getRoute()} />}/>
    </>
  );
};
