import React from "react";
import {
  GSForm,
  GSSidePanelPage,
  useFormValidation,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./order-item-form.scss";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import {
  getOrderForm,
  getPlayerForm,
  getSponsorForm,
  getTeamForm,
} from "../../../../forms/PackageForm";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRegistrationCustomer,
  selectRegistrationOrder,
  updateOrderForm,
  updatePlayer,
  updateSponsor,
  updateSponsorOrderForm,
  updateTeamOrderForm,
} from "../../../../reducers/tournamentSlice";
import { getRouting } from "../../../../helpers/Converters";
import {
  ORDER_ID,
  ORDER_ITEM_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";
import {
  clearUtility,
  clearUtilityNotifications,
  selectCroppedTarget,
  selectUtilityNotifications,
  uploadFile,
} from "../../../../reducers/utilitySlice";

/**
 *
 * @typedef Properties
 * @type {object}
 * @property {string} formType type of form to be shown
 *
 * @param {Properties} props formType
 */

const FORM_TYPE = ":formType";

//Name the component
const OrderItemForm = (props) => {
  const { formType } = props;

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "cart",
    ORDER_ID,
    ORDER_ITEM_ID,
    FORM_TYPE,
  ]);
  const match = useMatch(route);
  const orderItemID = getProp(match?.params, ORDER_ITEM_ID);
  const croppedImages = useSelector(selectCroppedTarget);

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const itemID = searchParams?.get("id") ?? "";

  const registrationOrder = useSelector(selectRegistrationOrder);
  const currentItem = registrationOrder?.registrationOrderItems?.find?.(
    (item) => item?.id === orderItemID
  );

  const contactDetails = useSelector(selectRegistrationCustomer)

  //order forms

  

  const findPlayer = (id) => {
    let player = currentItem.inProgressPlayers?.find?.((p) => p.id === id);
    if (player) {
      return player;
    }
    for (const t of currentItem.inProgressTournamentTeams) {
      let p = t.inProgressPlayers?.find?.((p) => p.id === id);
      if (p) {
        return p;
      }
    }
  };

  const findTeam = (id) => {
    return currentItem?.inProgressTournamentTeams?.find(
      (team) => team.id === id
    );
  };

  const findSponsor = (id) => {
    return currentItem?.inProgressSponsors?.find(
      (sponsor) => sponsor.id === id
    );
  };

  const initialValid = () => {
    if(formType === "sponsor"){
      if(itemID){
        const sponsor = findSponsor(itemID)
        return !!sponsor?.name
      }
    }
    return false
  }

  const [orderContext, isValid, setIsValid] = useFormValidation(initialValid());

  orderContext.contactDetails = contactDetails

  orderContext.playerIsUsingContactDetails = (inProgressPlayer) => {
    return (
      inProgressPlayer.firstName === contactDetails.firstName && 
      inProgressPlayer.lastName === contactDetails.lastName && 
      inProgressPlayer.email === contactDetails.email && 
      inProgressPlayer.phone === contactDetails.phone 
    )
  }

  orderContext.playerIsUsingContactDetails = (inProgressPlayer) => {
    return (
      inProgressPlayer.firstName === contactDetails.firstName && 
      inProgressPlayer.lastName === contactDetails.lastName && 
      inProgressPlayer.email === contactDetails.email && 
      inProgressPlayer.phone === contactDetails.phone 
    )
  }

  orderContext.updateData = (value, property, id) => {
    const { occurrence } = value?.registrationCustomField?.customFieldResponse;
    if (occurrence === "per_order") {
      setIsValid(true);
      let update = [...registrationOrder?.customFieldResponses];
      update[property] = value;
      dispatch(updateOrderForm(update));
    }
    if (occurrence === "per_team") {
      const team = findTeam(id);
      if (team) {
        setIsValid(true);
        let update = [...team?.customFieldResponses];
        update[property] = value;
        dispatch(
          updateTeamOrderForm({
            responses: update,
            teamID: team?.id,
            orderItemID: orderItemID,
          })
        );
      }
    }
    if (occurrence === "per_sponsor") {
      const sponsor = findSponsor(id);
      if (sponsor) {
        setIsValid(true);
        let update = [...sponsor?.customFieldResponses];
        update[property] = value;
        dispatch(
          updateSponsorOrderForm({
            responses: update,
            sponsorID: sponsor?.id,
            orderItemID: orderItemID,
          })
        );
      }
    }
    if (occurrence === "per_player") {
      const player = findPlayer(id);
      if (player) {
        setIsValid(true);
        let playerUpdate = { ...player };
        let update = [...player?.customFieldResponses];
        update[property] = value;
        playerUpdate.customFieldResponses = update;
        playerUpdate.orderItemID = currentItem.id;
        dispatch(updatePlayer(playerUpdate));
      }
    }
  };

  orderContext.getData = () => {
    return registrationOrder?.customFieldResponses;
  };

  orderContext.getSponsorData = (id) => {
    if (id) {
      const sponsor = findSponsor(id);
      return sponsor;
    }
    return currentItem?.inProgressSponsors?.filter?.((i) => i.id === itemID);
  };

  orderContext.getTeams = () => {
    return currentItem?.inProgressTournamentTeams?.filter?.(
      (i) => i.id === itemID
    );
  };

  orderContext.getPlayers = () => {
    return currentItem?.inProgressPlayers?.filter?.((i) => i.id === itemID);
  };

  orderContext.updateTeamPlayerData = (value, property, id) => {
    const player = findPlayer(id);
    setIsValid(true);
    let update = { ...player, orderItemID: currentItem?.id };
    update[property] = value;
    dispatch(updatePlayer(update));
  };

  orderContext.useContactInfo = (inProgressPlayer) => {
    const value = !orderContext.playerIsUsingContactDetails(inProgressPlayer)
    const player = findPlayer(inProgressPlayer.id);
    setIsValid(true);
    let update = { ...player, orderItemID: currentItem?.id };
    update.firstName = value ? contactDetails.firstName : "";
    update.lastName = value ?  contactDetails.lastName : "";
    update.email = value ?  contactDetails.email : "";
    update.phone = value ?  contactDetails.phone : "";
    dispatch(updatePlayer(update));
  };

  orderContext.updateSponsorData = (value, property, id) => {
    const sponsor = findSponsor(id);
    setIsValid(true);
    let update = { ...sponsor, orderItemID: currentItem?.id };
    update[property] = value;
    dispatch(updateSponsor(update));
  };

  orderContext.setBinding = (property, id) => {
    const player = findPlayer(id);
    return {
      value: player?.[property] ?? "",
      onChange: (e) => {
        orderContext?.updateTeamPlayerData?.(e?.target?.value, property, id);
      },
      failedValidation: orderContext.validationFailed,
      required: checkRequired(property),
    };
  };

  orderContext.setSponsorBinding = (property, id) => {
    const sponsor = findSponsor(id);
    return {
      value: sponsor?.[property] ?? "",
      onChange: (e) => {
        orderContext?.updateSponsorData?.(e?.target?.value, property, id);
      },
      failedValidation: orderContext.validationFailed,
    };
  };

  const checkRequired = (property) => {
    switch (property) {
      case "firstName":
        return currentItem?.tournamentPackage?.requirePlayerFirstName;
      case "lastName":
        return currentItem?.tournamentPackage?.requirePlayerLastName;
      case "email":
        return currentItem?.tournamentPackage?.requirePlayerEmail;
      case "phone":
        return currentItem?.tournamentPackage?.requirePlayerPhone;
      case "ghin":
        return currentItem?.tournamentPackage?.requirePlayerGhinOrHandicap;
      default:
        return false;
    }
  };

  //

  const navigate = useNavigate();
  const notifications = useSelector(selectUtilityNotifications);

  const save = async () => {
    if (formType === "sponsor") {
      if (croppedImages?.logo) {
        const logo = await dispatch(uploadFile(croppedImages.logo));
        if (logo) {
          orderContext.updateSponsorData(
            `${process.env.REACT_APP_GOLFSTATUS_BASE_API_URL}${logo?.payload?.data?.location}`,
            "logo",
            itemID
          );
        }
      }
      if (croppedImages?.logoMobile) {
        const logoMobile = await dispatch(uploadFile(croppedImages.logoMobile));
        if (logoMobile) {
          orderContext.updateSponsorData(
            `${process.env.REACT_APP_GOLFSTATUS_BASE_API_URL}${logoMobile?.payload?.data?.location}`,
            "logoMobile",
            itemID
          );
        }
      }
    }
    setIsValid(false);
    dispatch(clearUtility())
    navigate(-1);
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save & Continue",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    let header = "Add Details";

    switch (formType) {
      case "team":
        header = "Team Details";
        break;
      case "player":
        header = "Player Details";
        break;
      case "sponsor":
        header = "Sponsor Details";
        break;
      default:
        header = "Additional Forms";
        break;
    }
    return {
      title: header,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    if (formType === "team") {
      return (
        <GSForm
          formTitle="Team Details"
          formSections={getTeamForm(orderContext)}
        ></GSForm>
      );
    }

    if (formType === "player") {
      return (
        <GSForm
          formTitle="Player Details"
          formSections={getPlayerForm(orderContext)}
        ></GSForm>
      );
    }

    if (formType === "sponsor") {
      return (
        <GSForm
          formTitle="Sponsor Details"
          formSections={getSponsorForm(orderContext)}
        ></GSForm>
      );
    }

    return (
      <GSForm
        formTitle="Additional Forms"
        formSections={getOrderForm(orderContext)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearUtilityNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <order-item-form>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </order-item-form>
  );
};

//export the new namet
export default OrderItemForm;
