import {
  faLocation,
  faLocationPinLock,
  faRefresh,
  faReorder,
  faSearch,
  faStream,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
  GSPageSection,
  defaultLayouts,
  defaultBorders,
  defaultPadding,
  defaultTypography,
  defaultGapping,
  GSActionBar,
  GSImage,
  GSButton,
  states,
} from "golfstatus_react_components";
import React, { useCallback, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./events-list.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournaments,
  selectHasMoreTournaments,
  selectLoading,
  selectTournamentFilter,
  selectTournaments,
  setEventListFilter,
  setEventListLocation,
  setTournamentList,
  updateTournament,
} from "../../reducers/tournamentSlice";
import EventLineItem from "./event-line-item";
import { useTheme } from "../../hooks/themeHooks";
import { createNotification } from "../../app/api";

import {
  getOrganizationEventSponsors,
  getOrganizationLookup,
  getOrganizationSettings,
  selectOrganizationEventSponsors,
  selectOrganizationLookup,
  selectOrganizationSettings,
} from "../../reducers/organizationSlice";
import OrganizationHero from "./organization/organization-hero";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { getDate } from "../../helpers/Dates";
import { addNotification, clearNotifications, setNavigationItems } from "../../reducers/appSlice";

const EventsList = (props) => {
  const [search, setSearch] = useState("");
  const [locating, setLocating] = useState(true);

  const filter = useSelector(selectTournamentFilter);

  const match = useMatch("/*");
  const orgMatch = useMatch("/organization/:organizationID/*");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tournaments = useSelector(selectTournaments);
  const loadingTournaments = useSelector(selectLoading);
  const organizationLookup = useSelector(selectOrganizationLookup);
  const organizationEventSponsors = useSelector(
    selectOrganizationEventSponsors
  );
  const organizationSettings = useSelector(selectOrganizationSettings);

  const [, theme, getStyle] = useTheme();

  const getLocation = useCallback(() => {
    dispatch(clearNotifications());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const f = {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          };
          dispatch(setEventListLocation(f));
          setLocating(false);
        },
        (error) => {
          setLocating(false);
          if (error.code === 1) {
            dispatch(
              addNotification(
                createNotification(
                  "To view events in your area, enable location settings and refresh the page.",
                  faLocationPinLock,
                  "grey",
                  "",
                  0,
                  [
                    {
                      buttonIcon: faRefresh,
                      title: "refresh",
                      type: "grey",
                      onClick: () => {
                        getLocation();
                      },
                    },
                    {
                      buttonIcon: faTimesCircle,
                      onClick: () => {
                        dispatch(clearNotifications());
                      },
                    },
                  ],
                  "",
                  "",
                  "Location Settings Disabled"
                )
              )
            );
          } else {
            dispatch(
              addNotification(
                createNotification(
                  "Could not find location.",
                  faLocation,
                  "warning",
                  "",
                  0,
                  [
                    {
                      title: "try again",
                      onClick: () => {
                        getLocation();
                      },
                    },
                  ]
                )
              )
            );
          }
        }
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  useEffect(() => {
    const f = { ...filter };
    if (orgMatch?.params?.organizationID) {
      f.organization_key = orgMatch?.params?.organizationID;
    }
    dispatch(getTournaments(cleanFilter(f)));
  }, [filter, dispatch, orgMatch?.params?.organizationID]);

  useEffect(() => {
    if (orgMatch?.params?.organizationID) {
      dispatch(getOrganizationLookup(orgMatch?.params?.organizationID));
      dispatch(getOrganizationEventSponsors(orgMatch?.params?.organizationID));
      dispatch(getOrganizationSettings(orgMatch?.params?.organizationID));
    }
  }, [dispatch, orgMatch?.params?.organizationID]);

  useEffect(() => {
    dispatch(updateTournament({}))
    dispatch(setNavigationItems([]))
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faReorder,
        actionClick: () => {
          navigate("filter");
        },
      },
    ];
  };

  const clearSearch = () => {
    dispatch(setTournamentList([]));
    setSearch("");
    const searchFilter = { ...filter, name: "", page: 1 };
    dispatch(setEventListFilter(cleanFilter(searchFilter)));
  };

  const getDefaultSearch = () => {
    return (
      <GSActionBar
        header={
          <GSInput
            style={{
              ...getStyle(theme.surface),
              width: "100%",
              maxWidth: "400px",
            }}
            textValue={search}
            leftIcon={faSearch}
            rightIcon={faTimesCircle}
            rightIconClick={() => {
              clearSearch();
            }}
            placeholder="Search Events..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onSubmit={() => {
              dispatch(setTournamentList([]));
              const searchFilter = { ...filter, name: search, page: 1 };
              dispatch(setEventListFilter(cleanFilter(searchFilter)));
            }}
          ></GSInput>
        }
        pageActions={getSearchActions()}
      />
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find any events..."
        detail="Try updating the filters to find some."
        actions={[
          {
            title: "Update Filters",
            type: "black",
            buttonIcon: faStream,
            onClick: () => {
              navigate("filter");
            },
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find any events..."
        detail="Try changing the search term and filter or contact GolfStatus support."
        actions={[
          {
            title: "Clear All",
            type: "light-grey",
            onClick: () => {
              clearSearch();
            },
          },
          {
            title: "Update filter",
            type: "black",
            onClick: () => {
              navigate("filter");
            },
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    //return false;
    //
    if (orgMatch?.params?.organizationID) {
      return orgMatch?.params?.["*"] !== "";
    }
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getHeroBackground = () => {
    if (organizationLookup.bannerImage) {
      return defaultLayouts.hero.image(
        organizationLookup?.bannerImage,
        "300px"
      );
    }
    return {};
  };

  const getHeroBody = () => {
    if (organizationLookup?.logo) {
      return <OrganizationHero {...organizationLookup} />;
    }
    return (
      <div
        style={{
          ...defaultLayouts.vStack,
          ...defaultGapping.mediumLargeGap,
        }}
      >
        <h1 style={{ ...defaultTypography.headline1 }}>
          Events Powered By GolfStatus
        </h1>
        <div style={{ ...defaultTypography.bodyRegular.withOpacity(0.7) }}>
          Find events near you.
        </div>
      </div>
    );
  };

  const getAdGroup = (offset) => {
    let sponsors = [];

    if (
      organizationEventSponsors.length < organizationSettings.consecutiveAds
    ) {
      sponsors = organizationEventSponsors;
    } else {
      for (let i = 0; i < organizationSettings.consecutiveAds; i++) {
        let itemOffset = offset * (organizationSettings?.consecutiveAds ?? 0);
        let index = (itemOffset + i) % organizationEventSponsors.length;
        sponsors.push(organizationEventSponsors[index]);
      }
    }

    return (
      <GSItemList
        style={{ ...defaultLayouts.fullWidth }}
        type="vertical medium-gap"
        items={sponsors}
        listItem={(item) => (
          <div
            style={{
              ...defaultLayouts.fullWidth,
              ...defaultLayouts.vStack.align("center", "center"),
              ...getStyle(theme.surfaceVariant),
              ...defaultBorders.largeBorderRadius,
              ...defaultPadding.mediumLargePad,
              overflow: "hidden",
            }}
          >
            <GSImage
              style={{ maxWidth: "100%" }}
              src={item?.eventsSponsorLogo}
              size="large"
              ratio="ultra-wide"
            />
          </div>
        )}
      />
    );
  };

  const getEventList = () => {
    const ads = [];

    if (organizationEventSponsors?.length > 0) {
      for (
        let i = organizationSettings?.adStartPosition ?? 0;
        i < tournaments?.length ?? 0;
        i++
      ) {
        let offset = i - organizationSettings.adStartPosition ?? 0;
        if (offset % organizationSettings.eventsBetweenAds === 0) {
          ads.push({
            index: i,
            view: getAdGroup(ads?.length),
          });
        }
      }
    }

    return (
      <GSItemList
        style={{ ...defaultLayouts.fullWidth }}
        type="vertical selectable centered medium-large-gap"
        items={tournaments}
        indexItems={ads}
        listItem={(tournament) => (
          <EventLineItem
            className="hover-color-shift"
            style={{
              ...getStyle(theme.surfaceVariant),
              ...defaultLayouts.fullWidth,
              ...defaultBorders.mediumLargeBorderRadius,
              ...defaultPadding.xLargePad,
            }}
            {...tournament}
          />
        )}
        itemSelected={(tournament) => {
          navigate(getRouting(eventBaseRoute, [tournament?.publicUrl]));
        }}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={loadingTournaments?.length > 0 || locating}
        loadingMainText="Searching for tournaments near you..."
      />
    );
  };

  const getFilterButtonList = () => {
    return (
      <div style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumGap }}>
        <div style={{ ...defaultTypography.bodyRegular.withOpacity(0.7) }}>
          Filtered By
        </div>
        <GSItemList
          type="horizontal small-gap wrapped"
          items={getFilterButtons(filter)}
          listItem={(item) => (
            <GSButton
              onClick={() => {
                navigate("filter");
              }}
              title={item.label}
              type="grey secondary pill"
            />
          )}
        />
      </div>
    );
  };

  const getItemList = () => {
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <GSPageSection
          style={{
            ...getStyle(theme.outlineVariant),
            minHeight: "250px",
            ...getHeroBackground(),
          }}
          bodyStyle={{
            ...getStyle(theme.surfaceVariant),
            ...defaultBorders.largeBorderRadius,
            ...defaultPadding.smallLayoutPad.apply("vertical"),
            backdropFilter: "blur(4px)",
          }}
          body={[getHeroBody()]}
        />
        <GSPageSection
          style={{
            ...getStyle(theme.outlineVariant),
            ...defaultLayouts.stickyTop,
            ...getStyle(theme.surface),
            zIndex: 1,
            overflow: "visible",
            ...defaultPadding.xLargePad,
          }}
          bodyStyle={{ ...defaultPadding.noPad, overflow: "visible" }}
          body={[getDefaultSearch()]}
        />
        <GSPageSection
          style={{
            ...getStyle(theme.outlineVariant),
            ...defaultPadding.smallLayoutPad.apply("vertical"),
          }}
          bodyStyle={{ padding: "0", overflow: "visible" }}
          body={[getFilterButtonList(), getEventList()]}
        />
      </div>
    );
  };

  const hasMoreTournaments = useSelector(selectHasMoreTournaments);

  return (
    <events-list>
      <GSListPage
        headerStyle={{ display: "none" }}
        loadMore={() => {
          if (hasMoreTournaments && loadingTournaments.length === 0) {
            const update = { ...filter, page: filter.page + 1 };
            dispatch(setEventListFilter(cleanFilter(update)));
          }
        }}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </events-list>
  );
};

export default EventsList;

export const tournamentFilter = {
  name: "",
  include_closed_events: false,
  include_private_events: false,
  page: 1,
  past_events: false,
  search: "true",
  latitude: "",
  longitude: "",
  per_page: "15",
};

export const AdvancedFilter = {
  end_date: undefined,
  city: undefined,
  state: undefined,
  postal: undefined,
};

export const cleanFilter = (filter) => {
  const newFilter = {};

  for (const prop in filter) {
    if ((filter[prop] ?? "") !== "") {
      newFilter[prop] = filter[prop];
    }
  }

  return newFilter;
};

export const getFilterButtons = (filter, onClick) => {
  const buttons = [];

  if (filter?.include_closed_events) {
    buttons.push({
      label: "Open & Closed Registration",
    });
  } else {
    buttons.push({
      label: "Open Registration",
    });
  }

  if (filter?.include_private_events) {
    buttons.push({
      label: "Public & Private Events",
    });
  } else {
    buttons.push({
      label: "Public Events",
    });
  }

  if (filter?.past_events) {
    buttons.push({
      label: "Upcoming & Past Events",
    });
  } else {
    buttons.push({
      label: "Upcoming Events",
    });
  }

  if ((filter?.start_date ?? "") !== "") {
    buttons.push({
      label: `from ${getDate(filter.start_date)}`,
    });
  }

  if ((filter?.end_date ?? "") !== "") {
    buttons.push({
      label: `to ${getDate(filter.end_date)}`,
    });
  }

  if ((filter?.city ?? "") !== "") {
    buttons.push({
      label: `${filter.city}`,
    });
  }

  if ((filter?.state ?? "") !== "") {
    const state = states?.USA?.find((s) => s.value === filter.state)?.label;
    buttons.push({
      label: `${state}`,
    });
  }

  if ((filter?.postal ?? "") !== "") {
    buttons.push({
      label: `${filter.postal}`,
    });
  }
  return buttons;
};
