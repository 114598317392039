
import React from "react";


import AvailablePackagesList from "../events/event/packages/available-packages-list";
import { useTournamentPackages } from "../../hooks/tournamentHooks";

const AvailablePackagesWidget = () => {
  const {teamPackages, playerPackages} = useTournamentPackages();
 
  const packages = [...teamPackages, ...playerPackages]

  return (
    <AvailablePackagesList tournamentPackages={packages} />
  );
};

export default AvailablePackagesWidget;
