import React, {useEffect} from 'react';
import './customer-invoice-details.scss';
import {useMatch, useNavigate, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearNotifications, downloadCustomerInvoice, getCustomerInvoiceDetails, selectInvoiceDetails} from "../../reducers/invoiceSlice";
import {
  GSEmptyList,
  GSItemList,
  GSListPage,
  GSPageSection,
  defaultLayouts,
} from "golfstatus_react_components";
import {faCircleArrowDown} from "@fortawesome/free-solid-svg-icons";
import RegistrationOrderSummary from "../registration-order-summary";
import CustomerInvoiceSummary from "./customer-invoice-summary";
import CustomerInvoiceTotalBanner from "./customer-invoice-total-banner";
import CustomerInvoiceContactInformation from "./customer-invoice-contact-information";
import CustomerInvoiceBillingInformation from "./customer-invoice-billing-information";

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch("/invoice/:id/*");

  const details = useSelector(selectInvoiceDetails);

  useEffect(() => {
    if (match?.params?.id) {
      dispatch(getCustomerInvoiceDetails(match.params.id));
    }
  }, [dispatch, match?.params?.id]);

  const payInvoice = () => {
    dispatch(clearNotifications())
    navigate(`pay`);
  };

  const downloadInvoice = () => {
    dispatch(downloadCustomerInvoice(details?.id));
  };

  const getPageActions = () => {
    if (!details) {
      return [];
    }
    let actions = [];
    if (isInvoicePayable()) {
      actions.push({
        id: 1,
        buttonTitle: "Pay Invoice",
        type: "black",
        actionClick: payInvoice,
      });
    }
    actions.push({
      id: 2,
      buttonTitle: "Download Invoice",
      type: "grey mobile-icon",
      actionIcon: faCircleArrowDown,
      actionClick: downloadInvoice,
    });
    return actions;
  };

  const isInvoicePaid = () => {
    return details?.registrationOrder?.status === "paid";
  };

  const isInvoiceVoid = () => {
    return details?.registrationOrder?.status === "void";
  };

  const isInvoicePayable = () => {
    return !isInvoicePaid() && !isInvoiceVoid();
  };

  const getInvoiceInfo = () => {
    const context = {
      details: details,
    };

    return (
      <GSPageSection
      body={[
        <CustomerInvoiceSummary style={{...defaultLayouts.fullWidth}} {...context} />
      ]}/>
    );
  };

  const getBillingInfo = () => {
    const context = {
      details: details,
    };

    return (
      <GSPageSection
      body={[
        <CustomerInvoiceBillingInformation {...context} />
      ]}/>
    );
  };

  const getOrderSummary = () => {
    const context = {
      details: details,
      displayFees: isInvoicePaid() && details?.registrationOrder?.registrantCoversFees,
    };

    return (
      <GSPageSection
      body={[
        <RegistrationOrderSummary {...context} />
      ]}/>
    );
  };

  const getInvoiceTotal = () => {
    const context = {
      details: details,
      payInvoice: payInvoice,
    };

    return (
      <GSPageSection body={[
        <CustomerInvoiceTotalBanner {...context} />
      ]}/>
    );
  };

  const getContactInfo = () => {
    const context = {
      details: details,
    };

    return (
      <GSPageSection body={[
        <CustomerInvoiceContactInformation {...context} />
      ]} />
    );
  };

  const getInvoiceDetailItems = () => {
    return details ? [
      getInvoiceInfo(),
      getBillingInfo(),
      getOrderSummary(),
      getInvoiceTotal(),
      getContactInfo(),
    ] : [];
  };

  const getNoInvoiceMessage = () => {
    return (
      <GSEmptyList
        title="We couldn't find the invoice you're looking for..."
        detail="Reach out to the tournament organizer with any questions regarding invoices."
      />
    );
  };

  const getInvoiceDetails = () => {
    return (
      <GSItemList
        items={getInvoiceDetailItems()}
        type="vertical"
        emptyMessage={getNoInvoiceMessage()}
        listItem={(item) => item}
      ></GSItemList>
    );
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const isSidePanelOpen = () => {
    return match && match?.params?.["*"] !== "";
  };

  const getDetailsPage = () => {
    return {
      title: "Invoice Details",
      getItemList: getInvoiceDetails,
      getPageActions: getPageActions,
      getRouter: getRouter,
      isSidePanelOpen: isSidePanelOpen,
    };
  };

  return (
    <customer-invoice-details>
      <GSListPage { ...getDetailsPage() } />
    </customer-invoice-details>
  );
};

export default InvoiceDetails;
