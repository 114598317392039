import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSPageSection,
  defaultPadding,
  defaultLayouts,
  GSEmptyList,
} from "golfstatus_react_components";

import React, { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./event-sponsorships.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentSponsors,
  selectLoading,
  selectTournamentSponsors,
} from "../../../../reducers/tournamentSlice";
import { useHeaderStyle, useSectionStyles } from "../../../../hooks/themeHooks";
import { getRouting } from "../../../../helpers/Converters";
import { eventBaseRoute, TOURNAMENT_ID } from "../../../../routes/event";
import PageHeader from "../../../page-header";
import BecomeASponsorWidget from "../../../widgets/become-a-sponsor-widget";
import { useTechSponsors } from "../../../widgets/tech-sponsor-widget";
import AllSponsorsWidget from "../../../widgets/all-sponsors-widget";
import AvailableSponsorshipsWidget from "../../../widgets/available-sponsorships-widget";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import { usePermissions } from "../../../../hooks/permissionHooks";
import { useTournament, useTournamentPackages } from "../../../../hooks/tournamentHooks";

const EventSponsorships = (props) => {
  const tournament = useTournament();
  const sponsorships = useSelector(selectTournamentSponsors);
  const {sponsorPackages} = useTournamentPackages()

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "sponsorships",
    "*",
  ]);
  const match = useMatch(route);
  const tournamentID = tournament?.id;

  const loading = useSelector(selectLoading)

  const dispatch = useDispatch();

  const { defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const { headerStyle } = useHeaderStyle();

  const techSponsors = sponsorships.filter(
    (sponsor) => sponsor.sponsorshipType === "leaderboard"
  );

  const getPageActions = useNavigationButtons("sponsorships");

  const permissions = usePermissions();

  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentSponsors(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    //
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const hasTechSponsor = () => {
    return (
      techSponsors?.length > 0 ||
      !!tournament?.tournamentPromotion?.secondaryEventPhoto
    );
  };

  const [getTechSponsorSections] = useTechSponsors();

  const getEmptyList = () => {
    if(loading.length > 0){
      return
    }
    return (
      <GSEmptyList
        style={{
          ...defaultPadding.emptyListPad,
          ...defaultLayouts.fullWidth,
        }}
        title="No Sponsors Yet"
        detail={`${tournament?.name} does not have any sponsors.`}
      />
    );
  };

  const getItemList = () => {
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Sponsors" />

        {permissions?.packagesEnabled() && sponsorPackages?.length > 0 ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<BecomeASponsorWidget />]}
          />
        ) : null}

        {hasTechSponsor() ? (
          <GSPageSection
            {...defaultSectionStyle}
            body={getTechSponsorSections()}
          />
        ) : null}

        {sponsorships.length > 0 ? (
          <GSPageSection
            title="All Sponsors"
            {...defaultSectionStyle}
            body={[<AllSponsorsWidget />]}
          />
        ) : (
          <GSPageSection body={[getEmptyList()]} />
        )}

        {sponsorPackages.length > 0 && permissions.packagesEnabled() ? (
          <GSPageSection
            title="Available Sponsorships"
            style={defaultSectionStyle.style}
            bodyStyle={{
              ...defaultSectionStyle.bodyStyle,
              ...defaultPadding.noPad,
            }}
            body={[<AvailableSponsorshipsWidget />]}
          />
        ) : null}
      </div>
    );
  };

  return (
    <event-sponsorships>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
      ></GSListPage>
    </event-sponsorships>
  );
};

export default EventSponsorships;
