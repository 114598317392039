import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {thunkResponse, getUserPaymentOptionsPath} from "../app/api";
import gsApi from "../auth/auth";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";

export const getUserPaymentOptions = createAsyncThunk(
  "user/getUserPaymentOptions",
  async (id, thunkAPI) => {
    return await gsApi.get(getUserPaymentOptionsPath(id));
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: [],
    notifications: [],
    paymentOptions: [],
  },
  reducers: {
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    thunkResponse(builder, getUserPaymentOptions, (state, action) => {
      state.paymentOptions = action.payload.map((option) => {
        if (option.paymentType === "credit_card") {
          return {
            ...option,
            icon: faCircleUser,
            title: option.brand === "Mastercard" ? option.brand : `${option.brand} Card`,
            subTitle: `Ending in *${option.lastFour}`,
            fee: 0.05,
            feeLabel: '5% Fee',
            saved: true,
          };
        } else {
          return {
            ...option,
            icon: faCircleUser,
            title: `${option.brand || 'Bank'} Account`,
            subTitle: `Ending in *${option.lastFour}`,
            fee: 0.02,
            feeLabel: '2% Fee',
            saved: true,
          };
        }
      });
    });
  },
});

export const {
  clearNotifications,
} = userSlice.actions;

export const selectUserPaymentOptions = (state) => state.user.paymentOptions;

export default userSlice.reducer;
