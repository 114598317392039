import {
  faArrowRight,
  faFilter,
  faFlag,
  faTicket,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSEmptyList,
  GSPageSection,
  defaultPadding,
  defaultLayouts,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  selectRegistrationOrder,
} from "../../../reducers/tournamentSlice";
import "./event-packages.scss";
import { useHeaderStyle, useSectionStyles } from "../../../hooks/themeHooks";
import CartStepItem from "./cart/cart-step-item";
import {
  getOrderItemCount,
  getPriceFormatted,
  getRegistrationSummary,
} from "../../../models/Registration";
import { getRouting } from "../../../helpers/Converters";
import { TOURNAMENT_ID, eventBaseRoute } from "../../../routes/event";
import PageHeader from "../../page-header";
import PackageFilter from "./packages/package-filter";
import PackageList from "./packages/package-list";
import PageFooter from "./cart/page-footer";
import { useNavigationButtons } from "../../../hooks/pageHooks";
import { ACTIVE, usePermissions } from "../../../hooks/permissionHooks";
import {
  useTournament,
  useTournamentPackages,
} from "../../../hooks/tournamentHooks";
import PackageAccessWidget from "../../widgets/package-access-widget";

const EventPackages = (props) => {
  const navigate = useNavigate();

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID, "packages", "*"]);
  const match = useMatch(route);

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const filter = searchParams?.getAll("view") ?? "";

  const registrationOrder = useSelector(selectRegistrationOrder);
  const tournament = useTournament();

  const publicUrl = tournament?.publicUrl;

  const { style, headerSectionStyle } = useSectionStyles();

  const {
    tournamentPackages,
    accessiblePackages,
    teamPackages,
    playerPackages,
    sponsorPackages,
    addonPackages,
    hasAccessPackages,
  } = useTournamentPackages();

  const getPageActions = useNavigationButtons("packages");


  const permissions = usePermissions();
  const packagesEnabled = permissions.packagesEnabled();
  const privateEvent = !permissions.publicRegistration;


  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getEmptyActions = () => {
    const actions = [
      {
        title: "View Event Details",
        type: "grey",
        onClick: () => {
          navigate(getRouting(eventBaseRoute, [publicUrl]));
        },
      },
    ];
    if (permissions.donationsActive()) {
      actions.push({
        title: "Make a Donation",
        type: "black",
        onClick: () => {
          navigate(getRouting(eventBaseRoute, [publicUrl, "donate"]));
        },
      });
    }
    return actions;
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="No Packages Yet"
        detail={`${tournament?.name} does not have any packages available right now.`}
        actions={getEmptyActions()}
        style={defaultPadding.emptyListPad}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getAvailablePackages = () => {
    let available = [];
    if (sponsorPackages?.length > 0) {
      available.push({
        label: "Sponsorships",
        icon: faFlag,
        id: "sponsor",
      });
    }
    if (teamPackages?.length > 0) {
      available.push({
        label: "Team Registrations",
        icon: faUsers,
        id: "team",
      });
    }
    if (playerPackages?.length > 0) {
      available.push({
        label: "Player Registrations",
        icon: faUser,
        id: "player",
      });
    }
    if (addonPackages?.length > 0) {
      available.push({
        label: "Add-Ons & Extras",
        icon: faTicket,
        id: "extras",
      });
    }
    return available;
  };

  const availablePackages = getAvailablePackages();
  const filterLength = filter.length;

  useEffect(() => {
    if (packagesEnabled || privateEvent) {
      if (availablePackages.length === 1 && filterLength === 0) {
        navigate(`?view=${availablePackages?.[0]?.id}`, { replace: true });
      }
    }
  }, [
    navigate,
    availablePackages,
    filterLength,
    packagesEnabled,
    privateEvent,
  ]);

  const getHeaderSection = () => {
    const body = [];

    if(accessiblePackages.length > 0){
      body.push(<PackageFilter filter={filter} />)
    }

    if(hasAccessPackages && false ){
      body.push(<PackageAccessWidget/>)
    }

    return body;
  };

  const packageSections = (
    <>
      {tournamentPackages?.length !== 1 ? (
        <GSPageSection {...headerSectionStyle} body={getHeaderSection()} />
      ) : null}

      {filter?.includes?.("sponsor") ? (
        <GSPageSection
          title="Sponsorships"
          style={style}
          bodyStyle={{ ...defaultPadding.noPad, overflow: "visible" }}
          body={[<PackageList packages={sponsorPackages} />]}
        />
      ) : null}

      {filter?.includes?.("team") ? (
        <GSPageSection
          title="Team Registrations"
          style={style}
          bodyStyle={{ ...defaultPadding.noPad, overflow: "visible" }}
          body={[<PackageList packages={teamPackages} />]}
        />
      ) : null}

      {filter?.includes?.("player") ? (
        <GSPageSection
          title="Player Registrations"
          style={style}
          bodyStyle={{ ...defaultPadding.noPad }}
          body={[<PackageList packages={playerPackages} />]}
        />
      ) : null}

      {filter?.includes?.("extras") ? (
        <GSPageSection
          title="Add-Ons & Extras"
          style={style}
          bodyStyle={{ ...defaultPadding.noPad }}
          body={[<PackageList packages={addonPackages} />]}
        />
      ) : null}
    </>
  );

  const cartStepActions = [
    {
      title: "Continue",
      rightIcon: faArrowRight,
      type: "black",
      onClick: () => {
        dispatch(calculatePricing(registrationOrder));
        navigate(
          getRouting(eventBaseRoute, [
            tournament.publicUrl,
            "cart",
            registrationOrder?.id,
          ])
        );
      },
    },
  ];

  const getItemList = () => {
    if (!packagesEnabled && !privateEvent) {
      return "";
    }
    if (accessiblePackages?.length === 0) {
      return (
        <div style={{ ...defaultLayouts.vStack }}>
          <PageHeader header="Packages" />
          {getEmptyListMessage()}
        </div>
      );
    }
    const itemCount = getOrderItemCount(registrationOrder);
    const price = getPriceFormatted(registrationOrder);
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Packages" />
        {packageSections}
        <PageFooter showFooter={itemCount > 0}>
          <CartStepItem
            nextStep="Next Step: Add Contact Details"
            description={getRegistrationSummary(registrationOrder, price)}
            style={{
              ...defaultLayouts.vStack,
              ...defaultLayouts.fullWidth,
              maxWidth: "1080px",
            }}
            actions={cartStepActions}
          />
        </PageFooter>
      </div>
    );
  };

  const { headerStyle } = useHeaderStyle();

  return (
    <event-packages>
      <GSListPage
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
      ></GSListPage>
    </event-packages>
  );
};

export default EventPackages;

//package helpers--------------
export const isSponsorPackage = (p) => {
  return p.packageType === "sponsor" && p.state === ACTIVE;
};
export const isTeamPackage = (p) => {
  return (
    p.hasTeamPackageItem && p.packageType !== "sponsor" && p.state === ACTIVE
  );
};
export const isIndividualPackage = (p) => {
  return (
    p.hasPlayerPackageItem && p.packageType !== "sponsor" && p.state === ACTIVE
  );
};
export const isExtraPackage = (p) => {
  return (
    !p.hasPlayerPackageItem &&
    !p.hasTeamPackageItem &&
    p.packageType !== "sponsor" &&
    p.state === ACTIVE
  );
};

export const sortPackages = (tournamentPackages) => {
  const teamPackages = tournamentPackages?.filter?.((p) => isTeamPackage(p));
  const playerPackages = tournamentPackages?.filter?.((p) =>
    isIndividualPackage(p)
  );
  const sponsorPackages = tournamentPackages?.filter((p) =>
    isSponsorPackage(p)
  );
  const addonPackages = tournamentPackages?.filter((p) => isExtraPackage(p));
  return { teamPackages, playerPackages, sponsorPackages, addonPackages };
};
