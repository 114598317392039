import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
/**
 * Donation info group.
 *
 * @typedef Properties
 * @type {object}
 * @property {object} tournament tournament of sponsors
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const BecomeASponsor = (props) => {
  const { tournament, navigate, style } = props;

  const titleSection = {
    gap: "medium-large-gap",
    sectionItems: [
      {
        type: "headline-2 full-width",
        value: "Become a Sponsor Today!",
      },
      {
        type: "secondary full-width",
        value: `There are still available sponsorship opportunities for ${tournament?.name ??
          "this tournament"}!`,
      },
    ],
  };

  const titleColumn = {
    fit: "stretch",
    sections: [titleSection],
  };

  const actionSection = {
    sectionItems: [
      {
        type: "mobile-stretch",
        value: (
          <GSButton
            style={{ flexGrow: 1 }}
            title="Register Now"
            rightIcon={faArrowRight}
            type="black"
            onClick={() => {
              navigate?.();
            }}
          />
        ),
      },
    ],
  };

  const actionColumn = {
    type: "mobile-stretch",
    sections: [actionSection],
  };

  const getGroup = () => {
    let group = [titleColumn, actionColumn];
    return group;
  };

  return (
    <become-a-sponsor style={style}>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </become-a-sponsor>
  );
};

export default BecomeASponsor;
