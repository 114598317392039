import React, { useEffect, useState } from "react";
import "./golfstatus-payments.scss";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import {
  GSAppLayout,
  GSAppNavigationHeader,
  GSCircleImage,
  GSPageBanner,
  defaultLayouts,
  useMediaQuery,
} from "golfstatus_react_components";
import logo from "../images/GSLogo.svg";
import {
  faCartShopping,
  faCircleHalfStroke,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNotificationBanner } from "../hooks/notificationHooks";
import {
  clearNotifications,
  selectLoggedInUser,
  selectNavItems,
  selectNotifications,
  selectTheme,
  selectUser,
  setTheme,
} from "../reducers/appSlice";
import {
  selectRegistrationOrder,
} from "../reducers/tournamentSlice";
import { useTheme } from "../hooks/themeHooks";
import { getOrderItemCount } from "../models/Registration";
import { getCurrentUser } from "../app/gs-session";
import { eventBaseRoute, getEventRoutes } from "../routes/event";
import { getInvoiceRoutes } from "../routes/invoice";
import { getEventListRoutes } from "../routes/eventList";
import { getRouting } from "../helpers/Converters";
import { usePermissions } from "../hooks/permissionHooks";
import { navigateToLogin } from "./events/event/cart/user-checkout-info";
import { useTournament } from "../hooks/tournamentHooks";

const GolfstatusPayments = () => {
  const dispatch = useDispatch();
  const routeNavigate = useNavigate();
  const { pathname } = useLocation();

  const notifications = useSelector(selectNotifications);
  const tournament = useTournament();
  const navItems = useSelector(selectNavItems);
  const registrationOrder = useSelector(selectRegistrationOrder);
  const loggedInUser = useSelector(selectLoggedInUser);

  const [hideEnvironment, setHideEnvironment] = useState(false);
  const [, theme, getStyle] = useTheme();
  const { getMaxZoom } = useMediaQuery();

  const permissions = usePermissions();

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      dispatch(selectUser(user?.uid));
    }
  }, [dispatch]);

  const getTitle = () => {
    return (
      <GSAppNavigationHeader
        logo={logo}
        title={tournament?.name ?? ""}
      ></GSAppNavigationHeader>
    );
  };

  const getFooter = () => {
    const env =
      process.env.REACT_APP_ENV === "staging" ||
      process.env.REACT_APP_ENV === "development"
        ? "dev"
        : "com";

    const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";
    return [
      {
        id: 0,
        label: (
          <GSCircleImage
            url={loggedInUser?.avatar ? loggedInUser.avatar : defaultAvatar}
            size="large"
          ></GSCircleImage>
        ),
        isSelectable: true,
        url: `https://profile.golfstatus.${env}/account-settings`,
        isExternal: true,
      },
    ];
  };

  const navigate = (item) => {
    if (item.isExternal) {
      window.open(item?.url);
    } else {
      if (item?.url) {
        routeNavigate(item?.url);
      }
    }
  };

  const getEnvironmentActions = () => {
    return [
      {
        actionIcon: faTimesCircle,
        type: "navigation",
        size: "secondary",
        actionClick: hideNavEnvironment,
      },
    ];
  };

  const getShowEnvironment = () => {
    if (hideEnvironment) {
      return false;
    }
    return (
      process.env.REACT_APP_ENV !== "production" &&
      process.env.REACT_APP_ENV !== "demo"
    );
  };

  const hideNavEnvironment = () => {
    setHideEnvironment(true);
  };

  const getActiveRoute = () => {
    return `${pathname}`;
  };

  const getNav = () => {
    return {
      navItems: navItems,
      title: getTitle(),
      footerItems: getFooter(),
      itemSelected: navigate,
      activeRoute: getActiveRoute(),
      showEnv: getShowEnvironment(),
      environmentActions: getEnvironmentActions(),
      environment: `${process.env.REACT_APP_ENV} environment`,
      environmentDescription: process.env.REACT_APP_GOLFSTATUS_BASE_API_URL,
      style: { ...getStyle(theme.outlineVariant) },
    };
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getNavigationActions = () => {
    return [
      {
        title: "Clear",
        size: "secondary",
        onClick: timeoutAction,
      },
    ];
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications: notifications?.map?.((notification) => ({
      ...notification,
      bannerActions: notification.pageActions,
    })),
    navigationActions: getNavigationActions(),
    timeoutAction,
  });

  const getRouter = () => {
    return (
      <Routes>
        {getEventListRoutes()}
        {getEventRoutes()}
        {getInvoiceRoutes()}
      </Routes>
    );
  };

  const itemCount = getOrderItemCount(registrationOrder);
  const mode = useSelector(selectTheme);

  const getMobileActions = () => {
    const actions = [
      
    ];

    if(false){
      actions.push({
        buttonIcon: faCircleHalfStroke,
        onClick: () => {
          dispatch(setTheme(mode === "light" ? "dark" : "light"));
        },
      })
    }

    if (!loggedInUser.id) {
      actions.unshift({
        title: "Sign In",
        type: "grey no-wrap",
        onClick: () => {
          navigateToLogin()
        },
      });
    }

    if (
      tournament?.id &&
      registrationOrder?.id &&
      (permissions.packagesEnabled() || permissions.donationsActive())
    ) {
      actions.unshift({
        buttonIcon: faCartShopping,
        title: itemCount > 0 ? `${itemCount}` : "",
        onClick: () => {
          routeNavigate(
            getRouting(eventBaseRoute, [
              tournament?.publicUrl,
              "cart",
              registrationOrder.id,
            ])
          );
        },
      });
    }

    return actions;
  };

  return (
    <golfstatus-payments
      style={{ ...defaultLayouts.vStack, zoom: getMaxZoom(420) }}
    >
      <GSAppLayout
        nav={getNav()}
        navigationItemSelected={navigate}
        notification={
          notifications?.length > 0 ? (
            <GSPageBanner {...bannerNotifications} />
          ) : (
            false
          )
        }
        routes={getRouter()}
        navigation="top"
        mobileActions={getMobileActions()}
      ></GSAppLayout>
    </golfstatus-payments>
  );
};

export default GolfstatusPayments;
