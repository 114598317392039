import {
  GSInfoGroup,
  GSButton,
  GSItemList,
  defaultPadding,
  defaultBorders,
  defaultLayouts,
  defaultTypography,
  defaultGapping,
} from "golfstatus_react_components";
import React from "react";
import "./registration-order-line-item.scss";
import {
  faMinus,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";
import { useTheme } from "../../../../hooks/themeHooks";
import CartFormLineItem from "../cart/cart-form-line-item";
import {
  isPlayerIncomplete,
  isPlayerInfoRequired,
  isSponsorInfoRequired,
  isTeamInfoRequired,
  validatePlayer,
  validateSponsor,
  validateTeam,
} from "../../../../models/Registration";
import { useNavigate } from "react-router-dom";

/**
 * package info group to display information about a tournament package.
 * @typedef Properties
 * @type {object}
 * @property {string} sponsorshipType the type of sponsorship this filters on
 * @property {number} packagesAvailable the number of packages available
 * @property {string} startingAt the starting price for the packages
 * @property {string} cartActionLabel Label for the button that interacts wi
 * @property {object} cartActionIcon icon for cart button
 * @property {function} cartAction the navigation function
 * @property {function} formSelected the function that runs when a form inside the order item is selected will return the type of form [team, sponsor, player]
 * @property {function} addPackage the function that runs when the plus button is pressed
 * @property {function} removePackage the function that runs when the minus button is pressed
 * @property {string} style the style for this component
 *
 * @param {Properties} props name, cost, remainingPackages, description, cartActionIcon, cartActionLabel, cartAction, formSelected, addPackage, removePackage, style
 */

const RegistrationOrderLineItem = (props) => {
  const {
    quantity,
    tournamentPackage,
    inProgressPlayers,
    inProgressSponsors,
    inProgressTournamentTeams,
    donation,
    formSelected,
    addPackage,
    decrementPackage,
    removePackage,
    style,
  } = props;

  const { name, cost, remainingPackages } = tournamentPackage ?? {};

  const [, theme, getStyle] = useTheme();

  const navigate = useNavigate();

  const getCounter = () => {
    return (
      <div
        style={{
          ...defaultLayouts.hStack,
          ...defaultGapping.largeGap,
          alignItems: "center",
          ...defaultPadding.mediumPad,
          ...getStyle(theme.background),
          ...defaultBorders.mediumLargeBorderRadius,
        }}
      >
        <GSButton
          type={`secondary ${quantity > 1 ? "" : ""}`}
          buttonIcon={faMinus}
          isDisabled={quantity === 1}
          onClick={() => {
            if (quantity > 1) {
              decrementPackage?.(props);
            }
          }}
        />
        <div style={{ ...defaultTypography.mediumBoldBody }}>{quantity}</div>
        <GSButton
          type="secondary"
          buttonIcon={faPlus}
          isDisabled={quantity >= remainingPackages}
          onClick={() => {
            if(quantity < remainingPackages){
              addPackage?.(tournamentPackage);
            }
          }}
        />
      </div>
    );
  };

  const packageTypeInfoSection = {
    sectionItems: [
      {
        type: "full-width",
        style: { ...defaultTypography.mediumBoldBody },
        value: name,
      },
      {
        type: "body full-width",
        value: moneyWithCommas(cost),
      },
    ],
  };

  const deleteSection = {
    sectionItems: [
      {
        type: "",
        value: (
          <GSButton
            buttonIcon={faTrash}
            onClick={() => {
              removePackage?.(props);
            }}
          />
        ),
      },
    ],
  };

  const quantitySection = {
    sectionItems: [
      {
        type: "",
        value: getCounter(),
      },
    ],
  };

  const packageInfoColumn = {
    fit: "stretch",
    sections: [packageTypeInfoSection],
  };

  if (addPackage && decrementPackage) {
    packageInfoColumn.sections.push?.(quantitySection);
  }

  const quantityColumn = {
    sections: [deleteSection],
  };

  const getGroup = () => {
    let group = [packageInfoColumn, quantityColumn];
    return group;
  };

  const getItems = () => {
    let items = [];
    inProgressTournamentTeams?.forEach?.((team) => {
      items.push({
        label: "Team Details",
        type: "team",
        id: team?.id,
        team,
        required: isTeamInfoRequired(team, props),
        orderItem: props,
      });
    });
    inProgressSponsors?.forEach?.((sponsor) => {
      items.push({
        label: "Sponsor Details",
        type: "sponsor",
        id: sponsor?.id,
        sponsor,
        required: isSponsorInfoRequired(sponsor),
        orderItem: props,
      });
    });
    inProgressPlayers?.forEach?.((player) => {
      items.push({
        label: "Player Details",
        type: "player",
        id: player.id,
        player,
        required: isPlayerInfoRequired(player, props),
        orderItem: props,
      });
    });
    if (donation) {
      items.push({
        label: "Message To Organization",
        type: "donation",
        id: donation?.id,
        donation,
        orderItem: props,
      });
    }
    return items;
  };

  const getFormStatus = (item) => {
    let warnings = [];
    switch (item.type) {
      case "team":
        warnings = validateTeam(item.team, [], navigate, item.orderItem);
        if (warnings?.length > 0) {
          return "incomplete";
        } else {
          return "complete";
        }
      case "sponsor":
        warnings = validateSponsor(item.sponsor, [], navigate, item.orderItem);
        if (warnings?.length > 0) {
          return "incomplete";
        } else {
          return "complete";
        }

      case "player":
        warnings = validatePlayer(item.player, [], navigate, item.orderItem);
        if (warnings?.length > 0 || isPlayerIncomplete(item.player) ) {
          return "incomplete";
        } else {
          return "complete";
        }
      case "donation":
        if ((donation.notes ?? "") === "") {
          return "incomplete";
        } else {
          return "complete";
        }
      default:
        return "complete";
    }
  };

  const getStatusStyle = (status, required) => {
    if (status === "incomplete" && required) {
      return {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: getStyle(theme.error)?.color,
      };
    } else {
      return {};
    }
  };

  const getAction = (status) => {
    if (status === "incomplete") {
      if(donation){
        return [
          {
            title: "Add Message",
            buttonIcon: faPen,
            style: {
              ...getStyle(theme.surfaceContainerHigh),
              ...defaultBorders.mediumLargeBorderRadius,
              flexGrow: 1
            },
          },
        ];
      }
      return [
        {
          title: "Add Details",
          buttonIcon: faPen,
          style: {
            ...getStyle(theme.errorContainer),
            ...defaultBorders.mediumLargeBorderRadius,
            flexGrow: 1
          },
        },
      ];
    } else {
      return [
        {
          title: "Edit",
          type: "grey",
          buttonIcon: faPen,
          style: {
            ...getStyle(theme.surfaceContainerHigh),
            ...defaultBorders.mediumLargeBorderRadius,
            flexGrow: 1
          },
        },
      ];
    }
  };

  const getLabel = () => {
    return donation ? "Donation Message" : "Package Forms";
  };

  return (
    <registration-order-line-item style={{...defaultGapping.xLargeGap, ...defaultLayouts.vStack, ...style}}>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
      {getItems()?.length > 0 ? (
        <div
          style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumLargeGap }}
        >
          <div
            style={{
              ...defaultTypography.overline2,
              ...defaultTypography.uppercase,
              opacity: ".7",
            }}
          >
            {getLabel()}
          </div>
          <GSItemList
            type="vertical medium-large-gap selectable"
            style={defaultLayouts.fullWidth}
            items={getItems()}
            itemSelected={(item) => {
              formSelected?.(item);
            }}
            listItem={(fs) => {
              const status = getFormStatus(fs);
              return (
                <div
                  style={{
                    ...defaultPadding.largePad,
                    ...getStyle(theme.surface),
                    ...getStatusStyle(status, fs.required),
                    ...defaultLayouts.vStack,
                    ...defaultLayouts.fullWidth,
                    ...defaultBorders.mediumLargeBorderRadius,
                  }}
                >
                  <CartFormLineItem
                    formName={fs?.label}
                    status={status}
                    required={fs.required}
                    actions={getAction(status)}
                  />
                </div>
              );
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </registration-order-line-item>
  );
};

export default RegistrationOrderLineItem;
